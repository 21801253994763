/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import '@splidejs/react-splide/css';
import '@splidejs/react-splide/css/core';
/* eslint-disable unused-imports/no-unused-vars */
import 'video-react/dist/video-react.css'; // import css

import { Splide, SplideSlide } from '@splidejs/react-splide';
import Banner from 'components/Banner';
import Image from 'components/Image';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const HowToStart = () => {
  const BASE_PATH = `/assets/`;
  const services = [
    {
      image: '/assets/chat_phone.png',
      title: 'Je crée mon compte en quelques clics gratuitement .',
    },
    {
      image: '/assets/control.webp',
      title: 'Je paramètre ma collecte selon mes préférences',
    },
    {
      image: '/assets/sharing.webp',
      title: 'Je diffuse ma collecte via les réseaux sociaux .',
    },
    {
      image: '/assets/contact.webp',
      title: 'Je sms et je mail ma team pour propulser mon initiative .',
    },
    {
      image: '/assets/heart.png',
      title: 'J’invite mes proches à voter pour ma collecte .',
    },
    {
      image: '/assets/winner.webp',
      title:
        'Je gère mes fonds simplement et je dépense ma collecte quand je le veux .',
    },
  ];
  const [slide, setSlide] = useState(services[0]);
  const HowToStartSteps = [
    {
      image: 'step_1.webp',
      alt: 'créer',
      title: 'créer',
    },
    {
      image: 'step_2.webp',
      alt: 'paramétrer',
      title: 'paramétrer',
    },
    {
      image: 'step_3.webp',
      alt: 'diffuser',
      title: 'diffuser',
    },
    {
      image: 'step_4.webp',
      alt: 'encaisser',
      title: 'encaisser',
    },
    {
      image: 'step_5.webp',
      alt: 'voter',
      title: 'voter',
    },
    {
      image: 'step_6.webp',
      alt: 'dépenser',
      title: 'dépenser',
    },
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      setSlide(services[Math.floor(Math.random() * services.length)]);
    }, 2000);

    return () => clearInterval(interval);
  }, []);
  return (
    <div className="container mx-auto max-w-7xl md:px-8 lg:px-24">
      <Helmet title="Comment débuter - KOUDPOUSS" />
      <Banner
        image="fly_family.webp"
        currentPage={3}
        headerMessageContainer="items-end xl:ml-auto xl:mr-0 lg:text-left"
        bannerClass="h-banner-large"
      />
      <div className="bg-white pb-10">
        <br />
        <br />
        <br className="block lg:hidden" />
        <br className="block lg:hidden" />

        <h1 className="mx-auto my-7 px-3 text-center text-xl font-light text-black lg:text-2xl">
          Besoin d’un coup de pouce pour concrétiser{' '}
          <span className="text-primary">vos projets ?</span>
        </h1>
        <h2 className="mx-auto my-7 px-3 text-center text-xl font-light text-black lg:text-2xl">
          Koud Pouss, la collecte online qui vous{' '}
          <span className="text-primary">ressemble</span> et <br />
          <span className="text-primary">nous rassemble !</span>
        </h2>

        <div
          className="bg-right overflow-hid relative flex items-end justify-center bg-no-repeat px-3 before:absolute before:h-full before:w-full before:bg-white lg:mt-56 lg:h-[19rem] lg:bg-cover lg:before:bg-transparent"
          style={{
            backgroundImage: `url(${BASE_PATH}montagne.7622a31d1e56afaab276.webp`,
          }}
        >
          <div className="relative z-10 mb-3 flex flex-col gap-8 lg:flex-row lg:gap-16">
            <figure className="lg:w-1/2">
              <img
                src={slide.image}
                alt={slide.title}
                className="w-full -translate-y-0 scale-100 lg:-translate-y-[2.8rem] lg:scale-125 xl:-translate-y-[2.3rem]"
              />
            </figure>
            <aside className="card-gradient relative mx-auto max-w-md self-start rounded-3xl py-8 px-10 text-center text-xl text-white before:absolute before:left-[-20px] before:border-y-[10px] before:border-y-transparent before:border-r-yellow-500 sm:px-24 md:-bottom-4 lg:mx-0 before:lg:border-r-[30px]">
              <p>{slide.title}</p>
            </aside>
          </div>
        </div>
        <p className="mx-auto my-7 px-3 text-center text-xl font-light text-black lg:text-2xl">
          Une initiative en solo ou en commun, un évènement à célébrer ou une
          cause à soutenir. <br />
          <span className="text-primary">
            Et si vos envies prenaient vie ? C’est maintenant !
          </span>
        </p>
        <p className="mx-auto my-7 px-3 text-center text-xl font-light text-black lg:text-2xl">
          Koud Pouss est une plateforme innovante et intuitive qui facilite{' '}
          <br /> l’expérience utilisateur pour <br />
          <span className="text-primary">
            répondre toujours mieux à vos besoins.
          </span>
        </p>
        <p className="mx-auto my-7 px-3 text-center text-xl font-light text-black lg:text-2xl">
          La collecte en ligne 100% sécurisée pour des porteurs de projets
          satisfaits. <br /> Et vous, quand allez vous commencer ?
        </p>
        <Link to="/lancer-une-collecte">
          <span className="mx-auto block w-fit rounded-full bg-gradient-to-b from-orange-100 to-primary px-9 py-4 text-base font-bold leading-tight text-white">
            Je lance ma collecte
          </span>
        </Link>
        {/* PREVIOUS SLIDE */}
        <ul
          className="bg-center relative mx-20 mt-16 hidden flex-col items-center justify-between bg-contain bg-no-repeat text-black before:absolute before:h-full before:w-full before:bg-white md:flex-row lg:h-96 lg:items-stretch before:lg:hidden"
          style={{
            backgroundImage: `url(/assets/steps.png`,
          }}
        >
          <li className="relative mb-8 flex h-48 w-48 flex-col items-center justify-center rounded-full border-2 border-black lg:mb-0 lg:block lg:h-auto lg:w-1/3 lg:border-none">
            <img
              src="/assets/light.png"
              alt="Lumière"
              title="Création de votre compte simplifiée"
              className="lg:absolute lg:top-28 lg:left-24 xl:left-32 xl:top-24 2xl:top-20 2xl:left-40"
            />
            <p>
              <strong className="mr-2 text-xl lg:absolute lg:top-44 lg:left-7 lg:text-3xl xl:top-40 xl:text-6xl">
                1
              </strong>
              <strong className="text-xl lg:absolute lg:right-1/2 lg:top-44 xl:text-3xl">
                Créer
              </strong>
            </p>
            <strong className="text-xs text-primary lg:absolute lg:bottom-32 lg:left-12 xl:left-20 xl:bottom-24 xl:text-sm 2xl:left-24 2xl:bottom-20 2xl:text-base">
              Création de votre <br /> compte simplifiée
            </strong>
          </li>
          <li className="relative mb-8 flex h-48 w-48 flex-col items-center justify-center rounded-full border-2 border-black lg:mb-0 lg:block lg:h-auto lg:w-1/3 lg:border-none">
            <img
              src="/assets/setting.png"
              alt="Paramétrer"
              title="Paramétrer"
              className="top-28 lg:absolute lg:right-1/2 xl:top-24 2xl:top-20"
            />
            <p>
              <strong className="top-44 left-12 mr-2 text-xl lg:absolute lg:text-3xl xl:left-16 xl:top-40 xl:text-6xl">
                2
              </strong>
              <strong className="right-20 top-44 text-xl lg:absolute xl:text-3xl 2xl:right-1/4">
                Paramétrer
              </strong>
            </p>
            <strong className="text-center text-xs text-primary lg:absolute lg:left-12 lg:bottom-28 xl:left-20 xl:bottom-24 xl:text-sm 2xl:bottom-20 2xl:right-1/3 2xl:text-base">
              Paramétrage intuitif et <br /> modifiable à tout moment <br />
              de votre collecte
            </strong>
          </li>
          <li className="relative mb-8 flex h-48 w-48 flex-col items-center justify-center rounded-full border-2 border-black lg:mb-0 lg:block lg:h-auto lg:w-1/3 lg:border-none">
            <img
              src="/assets/spray.png"
              alt="Diffuser"
              title="Diffuser"
              className="right-1/2 top-28 lg:absolute xl:top-24 2xl:top-20"
            />
            <p>
              <strong className="left-16 top-44 mr-2 text-xl lg:absolute lg:text-3xl xl:left-20 xl:top-40 xl:text-6xl">
                3
              </strong>
              <strong className="right-20 top-44 text-xl lg:absolute lg:text-2xl xl:text-3xl 2xl:right-1/3">
                Diffuser
              </strong>
            </p>
            <strong className="bottom-24 right-20 text-center text-xs text-primary lg:absolute xl:right-1/4 xl:bottom-20 xl:text-sm 2xl:bottom-16 2xl:text-base">
              Diffusion de votre levée <br />
              de fonds grâce <br />à de nombreuses <br /> fonctionnalités
            </strong>
          </li>
        </ul>
        <ul
          className="bg-center relative mx-20 hidden flex-col items-center justify-between bg-contain bg-no-repeat text-black before:absolute before:h-full before:w-full before:bg-white md:flex-row lg:h-96 lg:items-stretch before:lg:hidden"
          style={{
            backgroundImage: `url(/assets/steps.png`,
          }}
        >
          <li className="relative mb-8 flex h-48 w-48 flex-col items-center justify-center rounded-full border-2 border-black md:mb-0 lg:block lg:h-auto lg:w-1/3 lg:border-none">
            <img
              src="/assets/money_transfer.png"
              alt="Encaisser"
              title="Encaisser"
              className="left-24 top-28 lg:absolute xl:left-32 xl:top-24 2xl:left-40 2xl:top-20"
            />
            <p>
              <strong className="top-44 left-7 mr-2 text-xl lg:absolute lg:text-3xl xl:top-40 xl:text-6xl">
                4
              </strong>
              <strong className="left-20 top-44 text-xl lg:absolute xl:text-3xl 2xl:right-1/2">
                Encaisser
              </strong>
            </p>
            <strong className="bottom-24 left-6 text-center text-xs text-primary lg:absolute xl:left-12 xl:bottom-16 xl:text-sm 2xl:bottom-8 2xl:left-16 2xl:text-base">
              Encaissement des dons par <br />
              carte bancaire et via stripe le <br />
              leader des moyens <br />
              de paiements <br />
              online
            </strong>
          </li>
          <li className="relative mb-8 flex h-48 w-48 flex-col items-center justify-center rounded-full border-2 border-black md:mb-0 lg:block lg:h-auto lg:w-1/3 lg:border-none">
            <img
              src="/assets/vote.png"
              alt="Voter"
              title="Voter"
              className="right-1/2 top-28 lg:absolute xl:top-24 2xl:top-20"
            />
            <p>
              <strong className="top-44 left-12 mr-2 text-xl lg:absolute lg:text-3xl xl:left-16 xl:top-40 xl:text-6xl">
                5
              </strong>
              <strong className="right-28 top-44 text-xl lg:absolute lg:text-2xl xl:right-36 xl:text-3xl 2xl:right-1/2">
                Voter
              </strong>
            </p>
            <strong className="right-1/3 bottom-28 text-center text-xs text-primary lg:absolute xl:bottom-24 xl:text-sm 2xl:bottom-16 2xl:text-base">
              Tentez de remporter la <br />
              Tirelire Solidaire <br />
              mensuelle.
            </strong>
          </li>
          <li className="relative mb-8 flex h-48 w-48 flex-col items-center justify-center rounded-full border-2 border-black md:mb-0 lg:block lg:h-auto lg:w-1/3 lg:border-none">
            <img
              src="/assets/watch.png"
              alt="Diffuser"
              title="Diffuser"
              className="right-1/2 top-28 lg:absolute xl:top-24 2xl:top-20"
            />
            <p>
              <strong className="left-16 top-44 mr-2 text-xl lg:absolute lg:text-3xl xl:left-20 xl:top-40 xl:text-6xl">
                6
              </strong>
              <strong className="left-1/2 top-44 text-xl lg:absolute xl:text-3xl">
                Gérer
              </strong>
            </p>
            <strong className="bottom-28 right-20 text-center text-xs text-primary lg:absolute xl:right-24 xl:bottom-24 xl:text-sm 2xl:right-36 2xl:bottom-16 2xl:text-base">
              Gérez vos fonds et
              <br /> dépensez votre collecte <br /> en toute simplicité
            </strong>
          </li>
        </ul>
        {/* CURRENT SLIDES */}
        <Splide
          options={{
            perPage: 3,
            perMove: 1,
            arrows: true,
            pagination: false,
            autoplay: true,
            gap: 34,
            rewind: false,
            type: 'loop',
            breakpoints: {
              640: {
                perPage: 1,
                perMove: 1,
                gap: 10,
              },
              980: {
                perPage: 2,
                perMove: 2,
                gap: 10,
              },
              1024: {
                perPage: 3,
                perMove: 3,
                gap: 10,
              },
              1280: {
                perPage: 4,
                perMove: 4,
                gap: 10,
              },
            },
          }}
        >
          {HowToStartSteps.map((step) => (
            <SplideSlide key={step.image} className="flex items-center">
              <figure className="flex w-full items-center justify-center">
                <img
                  src={`/assets/how_to_start/${step.image}`}
                  alt={step.alt}
                  title={step.title}
                  className="max-h-32 lg:max-h-max"
                />
              </figure>
            </SplideSlide>
          ))}
        </Splide>
        <p className="mx-auto mt-10 mb-36 px-3 text-center text-xl font-light text-black lg:text-2xl">
          Vous pouvez ouvrir un nombre illimité{' '}
          <span className="text-primary">de collectes gratuitement .</span>
        </p>
        <p className="mx-auto my-7 px-3 text-center text-xl font-light text-black lg:text-2xl">
          Sécurité des transactions,
          <span className="text-primary">nous protégeons votre don .</span>
        </p>
        <figure>
          <Image
            src="/assets/secure.png"
            loader="/assets/secure-tiny.png"
            alt="SSL"
            title="Sécurité des transactions,"
            className="mx-auto h-56"
          />
        </figure>
      </div>
    </div>
  );
};

export default HowToStart;
