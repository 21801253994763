/* eslint-disable */
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState } from 'react';

export const StripePaymentForm = ({ clientSecret, onSuccess, onError, userData }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);

  const handleFormChange = (event) => {
    const { complete, error } = event;
    setIsFormValid(complete && !error);
    if (error) {
      setErrorMessage(error.message);
    } else {
      setErrorMessage('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!stripe || !elements || !isFormValid) {
      return;
    }

    setIsProcessing(true);

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          payment_method_data: {
            billing_details: {
              name: `${userData.first_name} ${userData.last_name}`,
              email: userData.email,
            },
          },
        },
        redirect: 'if_required',
      });

      if (error) {
        const errorMessages = {
          'Your card number is incomplete.': 'Le numéro de carte est incomplet.',
          'Your card\'s expiration date is incomplete.': 'La date d\'expiration est incomplète.',
          'Your card\'s security code is incomplete.': 'Le code de sécurité est incomplet.',
          'Your postal code is incomplete.': 'Le code postal est incomplet.',
        };

        setErrorMessage(errorMessages[error.message] || error.message || "Une erreur est survenue");
        onError(error);
      } else if (paymentIntent && paymentIntent.status === 'succeeded') {
        onSuccess(paymentIntent);
      }
    } catch (err) {
      setErrorMessage("Une erreur est survenue lors du traitement du paiement");
      onError(err);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="w-full">
      <PaymentElement 
        onChange={handleFormChange}
        options={{
          layout: 'tabs',
          paymentMethodOrder: ['card'],
          defaultValues: {
            billingDetails: {
              name: `${userData.first_name} ${userData.last_name}`,
              email: userData.email,
            }
          },
          fields: {
            billingDetails: {
              name: 'never',
              email: 'never',
            }
          },
          terms: {
            card: 'never'
          }
        }}
      />
      
      {errorMessage && (
        <div className="mt-4 text-center text-sm text-red-500">
          {errorMessage}
        </div>
      )}

      <button
        type="submit"
        disabled={isProcessing || !stripe || !elements || !isFormValid}
        className={`mt-4 w-full rounded-lg bg-primary px-9 py-4 text-base font-bold text-white shadow-lg
          ${(isProcessing || !isFormValid) ? 'cursor-not-allowed opacity-50' : 'hover:bg-primary/90'}`}
      >
        {isProcessing ? 'Traitement en cours...' : 'Payer maintenant'}
      </button>
    </form>
  );
};
