import React from 'react';

const IconVote = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      version="1.1"
      viewBox="0 0 700 700"
      className="mx-auto"
    >
      <path
        fill={color}
        stroke={color}
        strokeWidth="20"
        d="m389.82 26.25c-2.2852 0.042969-4.4609 0.97266-6.0664 2.5977l-114.52 114.57h-133.57c-3.9961 0.011719-7.4766 2.7266-8.4609 6.5977l-30.609 119.97v-0.003906c-0.003906 0.035156-0.007813 0.070313-0.015625 0.10547l-0.050781 0.1875c-0.003907 0.023437-0.011719 0.046875-0.015626 0.070312-0.066406 0.26562-0.11719 0.53125-0.15625 0.80078-0.039062 0.27344-0.070312 0.54687-0.085937 0.82031v0.10156c-0.007813 0.11719-0.011719 0.23047-0.015625 0.34375v252.62c0.019531 4.832 3.9531 8.7344 8.7852 8.7148h490c4.8047-0.019531 8.6953-3.9102 8.7148-8.7148v-252.62-0.10547c0-0.17969-0.003906-0.36328-0.015625-0.54688-0.007813-0.074219-0.011719-0.14844-0.019531-0.21875-0.003906-0.042969-0.011719-0.082031-0.015625-0.12109-0.027344-0.19922-0.0625-0.39844-0.10156-0.59766-0.007812-0.050781-0.011718-0.10156-0.019531-0.15234-0.019531-0.10156-0.042969-0.20703-0.070313-0.30859-0.003906-0.027343-0.007812-0.054687-0.015624-0.085937l-0.11719-0.44531-30.488-119.82c-0.98828-3.875-4.4766-6.5938-8.4766-6.5938h-95.582l14.781-14.781v-0.003906c3.3906-3.4141 3.3906-8.9258 0-12.336l-87.449-87.449v-0.003906c-1.6719-1.6914-3.9609-2.6289-6.3398-2.5977zm0.13672 21.125 75.059 75.059-81.074 81.125h-79.895l-35.137-35.137 9.4492-9.4492v-0.003906c0.49609-0.39844 0.94922-0.85156 1.3516-1.3477zm-247.51 113.56h109.27l-1.3516 1.3516v-0.003906c-3.3945 3.4141-3.3945 8.9258 0 12.34l28.949 28.934h-26.422c-0.27734-0.015625-0.55859-0.015625-0.83594 0-4.75 0.33203-8.3633 4.4023-8.1367 9.1602 0.22656 4.7539 4.2109 8.4609 8.9727 8.3398h194.23c2.3555 0.050781 4.6328-0.84766 6.3164-2.4922 1.6836-1.6484 2.6289-3.9023 2.6289-6.2578 0-2.3555-0.94531-4.6133-2.6289-6.2578-1.6836-1.6484-3.9609-2.5469-6.3164-2.4922h-38.434l42.621-42.621h106.27l26.215 102.69h-467.51zm-28.68 120.19h472.48v235.12h-472.48z"
      />
    </svg>
  );
};

IconVote.propTypes = {
  size: IconVote.string,
  color: IconVote.string,
};
export default IconVote;
