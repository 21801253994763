import AMERICAN_EXPRESS_ICON from '../images/icons/amex.svg';
import DINERS_CLUB_ICON from '../images/icons/diners.svg';
import DISCOVER_ICON from '../images/icons/discover.svg';
import JCB_ICON from '../images/icons/JCB.svg';
import MASTER_CARD_ICON from '../images/icons/mastercard.svg';
import UNION_PAY_ICON from '../images/icons/unionPay.svg';
import VISA_ICON from '../images/icons/visa.svg';

export const OTHERCARDS = [
  /[1-9]/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export const AMERICANEXPRESS = [
  /[1-9]/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export const EXPIRYDATE = [/[0-9]/, /\d/, '/', /\d/, /\d/];
export const CVC = [/[0-9]/, /\d/, /\d/, /\d/];

export const CARDICON = {
  VISA: VISA_ICON,
  MASTERCARD: MASTER_CARD_ICON,
  DISCOVER: DISCOVER_ICON,
  AMERICAN_EXPRESS: AMERICAN_EXPRESS_ICON,
  DINERS_CLUB: DINERS_CLUB_ICON,
  JCB: JCB_ICON,
  UNION_PAY: UNION_PAY_ICON,
};

export const CARDARR = [
  'VISA',
  'MASTERCARD',
  'AMERICAN_EXPRESS',
  'DISCOVER',
  'DINERS_CLUB',
  'JCB',
  'UNION_PAY_ICON',
];
