/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types';
import React from 'react';

const Button = (props) => {
  return (
    <button
      type={props.type}
      className={`rounded-full p-4 ${props.className}`}
      onClick={props.onClick}
    >
      {props.text}
    </button>
  );
};

Button.defaultProps = {
  type: 'button',
};

Button.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
};

export default Button;
