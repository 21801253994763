/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import '@splidejs/react-splide/css';
import '@splidejs/react-splide/css/core';
/* eslint-disable unused-imports/no-unused-vars */
import 'video-react/dist/video-react.css'; // import css

import { Splide, SplideSlide } from '@splidejs/react-splide';
import { getCategories } from 'api/category';
import Banner from 'components/Banner';
import Event from 'components/Event';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

const StartADonation = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState([]);

  const fetchCategories = () => {
    setLoading(true);
    getCategories()
      .then((res) => {
        setLoading(false);
        setCategories(
          res.data.data?.sort((a, b) => a.created_at > b.created_at)
        );
      })
      .catch((err) => {
        setMessage([
          false,
          "La page n'a pas pu être chargée correctement, veuillez rafraîchir s'il vous plait",
        ]);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <div className="container mx-auto max-w-7xl md:px-8 lg:px-24">
      <Helmet title="Lancer une collecte - KOUDPOUSS" />
      <Banner
        image="happy-family.jpg"
        currentPage={1}
        headerMessageContainer="items-end xl:ml-auto xl:mr-0 lg:text-left"
      />
      <div className="bg-white">
        <br />
        <br />
        <br className="block lg:hidden" />
        <br className="block lg:hidden" />

        <h1 className="mx-auto px-3 py-7 text-center text-xl font-light text-black lg:text-2xl">
          Choisissez la catégorie de votre collecte
        </h1>

        {message && (
          <p className="mx-auto max-w-md pb-24 text-center text-primary">
            {message[1]}
          </p>
        )}
        {loading && (
          <div className="relative text-center">
            <div
              className={`mx-auto text-center transition-colors delay-300
                ${loading ? 'text-primary' : 'text-transparent'}`}
            >
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-live="polite"
                aria-busy="true"
                aria-labelledby="title-08a desc-08a"
                className="mx-auto h-16 w-16 md:h-24 md:w-24"
              >
                <title id="title-08a">loading</title>
                <path
                  d="M7 8H3V16H7V8Z"
                  className="animate animate-bounce fill-current "
                />
                <path
                  d="M14 8H10V16H14V8Z"
                  className="animate animate-bounce fill-current [animation-delay:.2s]"
                />
                <path
                  d="M21 8H17V16H21V8Z"
                  className="animate animate-bounce fill-current [animation-delay:.4s]"
                />
              </svg>
            </div>
          </div>
        )}
        <ul className="container mx-auto hidden grid-cols-2 flex-wrap gap-2 md:grid md:px-8 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
          {categories.map((event) => {
            return (
              <li key={`s_${event?.id}`} className="flex w-full justify-center">
                <Event data={event} />
              </li>
            );
          })}
        </ul>
        <Splide
          options={{
            perPage: 3,
            perMove: 3,
            arrows: false,
            pagination: false,
            autoplay: false,
            gap: 32,
            rewind: false,
            type: 'slide',
            breakpoints: {
              320: {
                perPage: 1,
                perMove: 1,
                gap: 10,
              },
              640: {
                perPage: 2,
                perMove: 2,
                gap: 64,
              },
            },
          }}
          className="md:hidden"
        >
          {categories?.map((event) => (
            <SplideSlide key={`spc_${event?.id}`}>
              <Event data={event} />
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </div>
  );
};

export default StartADonation;
