/* eslint-disable */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-extraneous-dependencies */
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { getCollect } from 'api/collection';
import { checkPaymentStatus, getMyProfile, makeAGift } from 'api/user';
import Banner from 'components/Banner';
import Header from 'components/Header';
import IconLock from 'components/icons/IconLock';

import { Field, Formik } from 'formik';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CARDARR } from 'utils/constants';
import * as Yup from 'yup';

import { StripePaymentForm } from '../components/payment/StripePaymentForm';

const PaymentInformationSchema = Yup.object().shape({
  amount: Yup.number()
    .typeError('Le montant doit être un nombre')
    .required('Veuillez entrer un montant')
    .min(1, 'Le montant minimum est de 1€'),
  koudpoussParticipation: Yup.number(),
  comment: Yup.string(),
  first_name: Yup.string().required('Le prénom est requis'),
  last_name: Yup.string().required('Le nom est requis'),
  publicName: Yup.string().required('Vous devez renseigner votre nom public'),
  email: Yup.string()
    .email("L'adresse email n'est pas valide")
    .required('Veuillez saisir votre adresse email'),
  hideIdentity: Yup.bool(),
  hideGift: Yup.bool(),
});

const reasonToGive = [
  {
    src: '/assets/help_twice.png',
    alt: "Il n'y a pas de petit geste, juste de grandes actions",
  },
  {
    src: '/assets/media_quality.webp',
    alt: "Chaque soutien est essentiel pour nous permettre de continuer d'exister et d'offrir la meilleure expérience de la collecte en ligne la plus solidaire. !",
  },
  {
    src: '/assets/thanks_for_donation.jpeg',
    alt: "Nous reversons une partie de vos soutiens à des organisations engagées et ça c'est nulle part ailleurs que sur l'koudpouss",
  },
  {
    src: '/assets/why_to_give.jpeg',
    alt: "Nous reversons une partie de vos soutiens à des organisations engagées et ça c'est nulle part ailleurs que sur l'koudpouss",
  },
];

const DEFAULT_LAKUASDRA_GIFT = [
  {
    donation: 10,
    value: 1,
  },
  {
    donation: 29,
    value: 1.8,
  },
  {
    donation: 39,
    value: 2.6,
  },
  {
    donation: 50,
    value: 3,
  },
  {
    donation: 69,
    value: 3.5,
  },
  {
    donation: 99,
    value: 3.8,
  },
  {
    donation: 149,
    value: 4.8,
  },
  {
    donation: 199,
    value: 6.6,
  },
  {
    donation: 399,
    value: 8,
  },
  {
    donation: 599,
    value: 12,
  },
  {
    donation: 999,
    value: 15,
  },
  {
    donation: 1499,
    value: 20,
  },
  {
    donation: 1999,
    value: 28,
  },
  {
    donation: 2999,
    value: 45,
  },
  {
    donation: 3999,
    value: 80,
  },
  {
    donation: 4999,
    value: 90,
  },
];

const default_Donation = (mainDonation) => {
  if (!mainDonation || isNaN(mainDonation)) return 0;
  
  const anotherDonation = DEFAULT_LAKUASDRA_GIFT.find((index) => {
    return mainDonation <= index.donation;
  });
  return anotherDonation?.value || DEFAULT_LAKUASDRA_GIFT[0].value;
};

// Initialiser Stripe en dehors du composant
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Payment = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  // Déplacer l'initialisation du slug avant son utilisation
  const [slug, setSlug] = useState(() => {
    const pathParts = location.pathname?.split('/');
    return pathParts?.length >= 3 ? pathParts[2]?.toLowerCase() : null;
  });
  const [isSlugReady, setIsSlugReady] = useState(!!slug);
  
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [message, setMessage] = useState([]);
  const [collectionId, setCollectionId] = useState(null);
  const [creditCardSelected, setCreditCardSelected] = useState(CARDARR[0]);
  const [enableToProcessMessage, setEnableToProcessMessage] = useState(null);
  
  const [userData, setUserData] = useState(null);
  const formRef = useRef(null);

  const [slide, setSlide] = useState(0);
  const [percentGift, setPercentGift] = useState(1);
  const [showStripeForm, setShowStripeForm] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);

  // Amélioration de l'initialisation des valeurs
  const [initialAmount, setInitialAmount] = useState(() => {
    const locationAmount = location.state?.amount;
    const parsedAmount = locationAmount ? parseFloat(locationAmount) : null;
    return !isNaN(parsedAmount) && parsedAmount > 0 ? parsedAmount : null;
  });

  // Mise à jour des valeurs initiales du formulaire
  const [formValues, setFormValues] = useState(() => {
    const amount = initialAmount?.toString() || '';
    return {
      amount,
      koudpoussParticipation: initialAmount ? default_Donation(initialAmount).toString() : '',
      comment: '',
      first_name: '',
      last_name: '',
      publicName: '',
      email: '',
      hideIdentity: false,
      hideGift: false,
    };
  });

  // Modification du handleAmountChange pour une meilleure expérience utilisateur
  const handleAmountChange = (value, setFieldValue) => {
    // Permet la suppression complète
    if (value === '' || value === '0' || value === '0.') {
      setFieldValue('amount', '', false);
      setFieldValue('koudpoussParticipation', '0', false);
      return;
    }

    // Nettoyage : garde uniquement les chiffres et un point
    const cleanValue = value.replace(/[^\d.]/g, '');
    
    // Vérifie qu'il n'y a qu'un seul point décimal
    if ((cleanValue.match(/\./g) || []).length > 1) return;
    
    // Permet la saisie progressive (ex: "1.", "1.5")
    if (cleanValue.endsWith('.') || cleanValue.endsWith('.0')) {
      setFieldValue('amount', cleanValue, false);
      return;
    }

    const parsedValue = parseFloat(cleanValue);
    if (!isNaN(parsedValue)) {
      const formattedAmount = Math.min(parsedValue, 999999).toString();
      
      // Calcul de la participation en pourcentage actuel
      const currentParticipation = parseFloat(formRef.current?.values.koudpoussParticipation || 0);
      const currentAmount = parseFloat(formRef.current?.values.amount || 0);
      const participationPercentage = currentAmount > 0 ? (currentParticipation / currentAmount) * 100 : percentGift;
      
      // Calcul de la nouvelle participation
      const newParticipation = (parsedValue * participationPercentage / 100).toFixed(2);
      
      setFieldValue('amount', formattedAmount, false);
      setFieldValue('koudpoussParticipation', newParticipation, false);
      setPercentGift(participationPercentage);
    }
  };

  const updateParticipation = (action) => {
    if (action === 'add' && percentGift < 30) {
      setPercentGift(percentGift + 1);
    } else if (action === 'reduce' && percentGift > 1) {
      setPercentGift(percentGift - 1);
    }
  };

  useEffect(() => {
    const range = [0, 1];
    const interval = setInterval(() => {
      setSlide(Math.floor(Math.random() * range.length));
    }, 2000);

    // disable mouse wheel on all inputs number
    document.querySelectorAll('input[type=number]').forEach((el) => {
      el.addEventListener('mousewheel', (e) => {
        e.preventDefault();
      });
    });
    return () => {
      clearInterval(interval);
      document.querySelectorAll('input[type=number]').forEach((el) => {
        el.removeEventListener('mousewheel', (e) => {
          e.preventDefault();
        });
      });
    };
  }, []);

  const anErrorAppear = () => {
    setLoading(false);
    setMessage([false, 'Une erreur est survenue, veuillez réessayer']);
    setLoadingData(false);
  };

  // MAKE A GIFT
  const sendDonation = async (donation, id) => {
    setLoading(true);
    makeAGift(collectionId || id, donation)
      .then((res) => {
        setLoadingData(false);
        setMessage([true, 'Don envoyé !!! Merci pour votre contribution.']);
        setLoading(false);
        setTimeout(() => {
          navigate(`/collecte/${slug}`);
        }, 2000);
      })
      .catch((err) => {
        anErrorAppear();
      });
  };

  const fetchCollection = async (withMakePayment, paymentData) => {
    if (!slug) {
      setEnableToProcessMessage(
        <>
          <h1 className="mt-10 text-2xl font-bold">
            URL invalide
          </h1>
          <p className="mt-4">
            L'URL de la collecte est invalide ou manquante.
          </p>
          <Link to="/" className="relative mx-auto mt-4 block w-fit rounded-full bg-primary/90 px-9 py-3">
            Retourner à l'accueil
          </Link>
        </>
      );
      setLoadingData(false);
      return;
    }

    try {
      setLoadingData(true);
      const response = await getCollect(slug);
      
      if (!response?.data?.data) {
        throw new Error('Données de collection invalides');
      }

      const collectionData = response.data.data;
      setCollectionId(collectionData.id);

      // Vérification de la fin de la collecte
      if (collectionData.set_end_collect === 1) {
        setEnableToProcessMessage(
          <>
            <h1 className="mt-10 text-2xl font-bold">
              La collecte est terminée !
            </h1>
            <p className="mt-4">
              Cette collecte touche à sa fin, vous ne pouvez plus participer.
            </p>
            <Link to="/" className="relative mx-auto mt-4 block w-fit rounded-full bg-primary/90 px-9 py-3">
              Retourner à l'accueil
            </Link>
          </>
        );
        return;
      }

      // Si on doit faire un paiement
      if (withMakePayment && paymentData) {
        await sendDonation(paymentData, collectionData.id);
      }

      if (!withMakePayment) {
        setLoadingData(false);
      }

    } catch (error) {
      console.error('Erreur lors de la récupération de la collection:', error);
      
      if (error?.response?.status === 404) {
        setEnableToProcessMessage(
          <>
            <h1 className="mt-10 text-2xl font-bold">
              Nous n'avons pas trouvé cette collecte
            </h1>
            <p className="mt-4">
              Si vous avez reçu un lien pour participer à cette collecte,
              veuillez vérifier que vous avez bien copié l'adresse complète.
            </p>
            <Link to="/" className="relative mx-auto mt-4 block w-fit rounded-full bg-primary/90 px-9 py-3">
              Retourner à l'accueil
            </Link>
          </>
        );
      } else {
        setMessage([
          false,
          'Une erreur est survenue lors de la récupération de la collecte. Veuillez réessayer.'
        ]);
      }
      setLoadingData(false);
    }
  };

  // Mise à jour du slug quand location change
  useEffect(() => {
    const currentSlug = location.pathname?.split('/')?.[2]?.toLowerCase();
    if (currentSlug !== slug) {
      setSlug(currentSlug);
      setIsSlugReady(!!currentSlug);
    }
  }, [location.pathname, slug]);

  // Amélioration de l'initialisation
  useEffect(() => {
    let isSubscribed = true;

    const initializeCollection = async () => {
      try {
        if (userData) {
          // Si l'utilisateur est connecté, on récupère d'abord son profil
          await getUser();
        } else {
          // Sinon on récupère directement la collection
          await fetchCollection();
        }
      } catch (error) {
        console.error('Erreur lors de l\'initialisation:', error);
        if (isSubscribed) {
          setMessage([false, 'Une erreur est survenue lors du chargement. Veuillez réessayer.']);
          setLoadingData(false);
        }
      }
    };

    initializeCollection();

    // Cleanup function
    return () => {
      isSubscribed = false;
    };
  }, [slug]); // Ajout de slug comme dépendance

  // Amélioration de la fonction getUser
  const getUser = async () => {
    try {
      const response = await getMyProfile();
      if (!response?.data?.user?.original?.data) {
        throw new Error('Données utilisateur invalides');
      }

      const user = response.data.user.original.data;
      setUserData(user);

      // On attend que la collection soit récupérée avant de continuer
      await fetchCollection();

      if (formRef.current) {
        const currentAmount = formRef.current.values.amount;
        const currentParticipation = formRef.current.values.koudpoussParticipation;
        
        formRef.current.setValues({
          ...formRef.current.values,
          first_name: user.surname || '',
          last_name: user.name || '',
          publicName: `${user.surname || ''} ${user.name || ''}`.trim(),
          email: user.email || '',
          // Préserver les valeurs du montant et de la participation
          amount: currentAmount,
          koudpoussParticipation: currentParticipation,
        }, false);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération du profil:', error);
      // En cas d'erreur sur le profil, on essaie quand même de récupérer la collection
      await fetchCollection();
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  const handlePaymentClick = async () => {
    if (!formRef.current) return;

    const { values, validateForm } = formRef.current;
    const errors = await validateForm(values);

    // Log pour debug
    console.log('Form values:', values);
    console.log('Form errors:', errors);

    if (Object.keys(errors).length === 0) {
      try {
        setLoading(true);
        const paymentData = {
          amount: parseFloat(values.amount),
          email: values.email,
          first_name: values.first_name,
          last_name: values.last_name,
          publicName: values.publicName,
          commentary: values.comment || '',
          mask_identity: values.hideIdentity,
          mask_amount: values.hideGift,
          koudpoussParticipation: parseFloat(values.koudpoussParticipation) || 0,
          payment_method: 'cb'
        };

        const response = await makeAGift(collectionId, paymentData);
        if (response?.data?.clientSecret) {
          setClientSecret(response.data.clientSecret);
          setShowStripeForm(true);
        }
      } catch (error) {
        console.error('Payment error:', error);
        setMessage([false, "Une erreur est survenue lors du paiement"]);
      } finally {
        setLoading(false);
      }
    } else {
      // Afficher un message d'erreur spécifique
      const errorMessages = Object.values(errors).join(', ');
      setMessage([false, `Veuillez corriger les erreurs suivantes : ${errorMessages}`]);
    }
  };

  const handlePaymentSuccess = (paymentIntent) => {
    setMessage([true, 'Don envoyé !!! Merci pour votre contribution.']);
    setTimeout(() => {
      navigate(`/collecte/${slug}`);
    }, 2000);
  };

  // Modification du calcul du total
  const calculateTotal = (amount, participation) => {
    const amountNum = parseFloat(amount || 0);
    const participationNum = parseFloat(participation || 0);
    return (amountNum + participationNum).toFixed(2);
  };

  // Mise à jour de l'effet d'initialisation
  useEffect(() => {
    if (formRef.current && initialAmount) {
      const formattedAmount = initialAmount.toFixed(2);
      const participation = default_Donation(initialAmount).toFixed(2);
      
      formRef.current.setValues({
        ...formRef.current.values,
        amount: formattedAmount,
        koudpoussParticipation: participation
      }, false);
    }
  }, [initialAmount]);

  const DisplayAmount = React.memo(({ value }) => {
    const amount = parseFloat(value || 0);
    return (
      <span className="font-medium text-primary">
        {!isNaN(amount) ? amount.toFixed(2) : '0.00'} €
      </span>
    );
  });

  // Modification de handleParticipationValidation pour assurer la cohérence
  const handleParticipationValidation = (setFieldValue, amount, percentGift) => {
    const parsedAmount = parseFloat(amount || 0);
    const participationAmount = (parsedAmount * percentGift) / 100;
    
    // Mise à jour synchrone des valeurs
    setFieldValue('amount', parsedAmount.toFixed(2), false);
    setFieldValue('koudpoussParticipation', participationAmount.toFixed(2), false);
    
    // Force la mise à jour du formulaire
    formRef.current?.validateForm();
  };

  return (
    <div className="container mx-auto max-w-7xl md:px-8 lg:px-24">
      <Helmet title="Offrir un don - KOUDPOUSS" />
      {!enableToProcessMessage && (
        <div>
          <Banner
            image="happy-family.jpg"
            currentPage={1}
            headerMessageContainer="items-end xl:ml-auto xl:mr-0 lg:text-left"
          />
          <div className="bg-white pb-20 pt-10">
            <h1 className="mb-5 text-center text-xl font-medium text-primary">
              Je supporte avec un don
            </h1>
            <Formik
              innerRef={formRef}
              initialValues={formValues}
              validationSchema={PaymentInformationSchema}
              validateOnChange={false}
              enableReinitialize={true}
              onSubmit={handlePaymentClick}
            >
              {({ errors, touched, setFieldValue, getFieldMeta, values }) => (
                <div className="mx-auto flex w-full flex-col space-y-4 px-6 sm:max-w-3xl sm:px-10">
                  {/* Contribute to the collection */}
                  <div className="mx-auto flex w-full flex-col space-y-4 px-6 sm:max-w-3xl sm:px-10">
                    {/* Default gift amount */}
                    {initialAmount > 0 && (
                      <span
                        type="button"
                        className="mx-auto w-fit rounded-full border border-primary px-5 py-1 font-bold text-red-500"
                      >
                        {initialAmount} €
                      </span>
                    )}
                    {/* Gift amount */}
                    <Field
                      name="amount"
                      type="text"
                      inputMode="decimal"
                      placeholder="Je choisis le montant de mon don"
                      className={`w-full rounded border ${
                        errors.amount && touched.amount ? 'border-red-500' : 'border-black'
                      } bg-white px-3 py-1`}
                      value={values.amount}
                      onChange={(e) => handleAmountChange(e.target.value, setFieldValue)}
                      onBlur={(e) => {
                        if (e.target.value) {
                          const parsedValue = parseFloat(e.target.value);
                          if (!isNaN(parsedValue)) {
                            handleAmountChange(parsedValue.toFixed(2), setFieldValue);
                          }
                        }
                      }}
                    />
                    {errors.amount && touched.amount ? (
                      <div className="text-red-400">{errors.amount}</div>
                    ) : null}
                    {/* Gift amount */}
                    <label htmlFor="comment" className="block pt-5 text-center">
                      <span>Laisser un commentaire</span>
                      <Field
                        name="comment"
                        id="comment"
                        as="textarea"
                        className="mx-auto mt-2 block w-full border p-4 sm:w-10/12"
                        rows="10"
                      />
                    </label>
                    {errors.comment && touched.comment ? (
                      <div className="text-red-400">{errors.comment}</div>
                    ) : null}
                    <h2 className="-mb-5 pt-3 text-center text-xl font-normal text-primary">
                      Vos informations
                    </h2>
                    {/* Name and Hide my identity */}
                    <fieldset className="flex flex-wrap justify-between">
                      {/* Prénom et Nom */}
                      <div className="mt-5 w-full md:w-[48%]">
                        <label className="block">
                          Prénom
                          <Field
                            name="first_name"
                            placeholder="Votre prénom"
                            className="mt-1 w-full rounded border px-2 py-1"
                          />
                        </label>
                        {errors.first_name && touched.first_name ? (
                          <div className="text-red-400">
                            {errors.first_name}
                          </div>
                        ) : null}
                      </div>

                      <div className="mt-5 w-full md:w-[48%]">
                        <label className="block">
                          Nom
                          <Field
                            name="last_name"
                            placeholder="Votre nom"
                            className="mt-1 w-full rounded border px-2 py-1"
                          />
                        </label>
                        {errors.last_name && touched.last_name ? (
                          <div className="text-red-400">{errors.last_name}</div>
                        ) : null}
                      </div>

                      {/* Email */}
                      <div className="mt-5 w-full">
                        <label className="block">
                          Adresse email
                          <Field
                            name="email"
                            type="email"
                            placeholder="Votre adresse email"
                            className="mt-1 w-full rounded border px-2 py-1"
                          />
                        </label>
                        {errors.email && touched.email ? (
                          <div className="text-red-400">{errors.email}</div>
                        ) : null}
                      </div>

                      {/* Nom public */}
                      <div className="mt-5 w-full">
                        <label className="block">
                          Nom public (affiché sur la collecte)
                          <Field
                            name="publicName"
                            placeholder="Nom affiché publiquement"
                            className="mt-1 w-full rounded border px-2 py-1"
                          />
                        </label>
                        {errors.publicName && touched.publicName ? (
                          <div className="text-red-400">
                            {errors.publicName}
                          </div>
                        ) : null}
                      </div>
                    </fieldset>
                    {/* Hide my identity */}
                    <label
                      className="mt-5 flex w-56 items-center justify-between"
                      htmlFor="hideIdentity"
                    >
                      Masquer mon identité
                      <div className="relative ml-5 inline-flex cursor-pointer items-center">
                        <Field
                          id="hideIdentity"
                          name="hideIdentity"
                          type="checkbox"
                          className="peer sr-only"
                          checked={getFieldMeta('hideIdentity').value}
                          onChange={() => {
                            setFieldValue(
                              'hideIdentity',
                              !getFieldMeta('hideIdentity').value
                            );
                            if (getFieldMeta('hideGift').value)
                              setFieldValue(
                                'hideGift',
                                !getFieldMeta('hideGift').value
                              );
                          }}
                        />
                        <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-primary peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary" />
                      </div>
                    </label>
                    {/* Email and hide my gift */}
                    <fieldset className="flex flex-wrap justify-between">
                      {/* Hide my gift */}
                      <label
                        htmlFor="hideGift"
                        className="mt-5 flex w-56 items-center justify-between"
                      >
                        Masquer mon don
                        <div className="relative ml-5 inline-flex cursor-pointer items-center">
                          <Field
                            id="hideGift"
                            name="hideGift"
                            type="checkbox"
                            className="peer sr-only"
                            onChange={() => {
                              setFieldValue(
                                'hideGift',
                                !getFieldMeta('hideGift').value
                              );
                              if (getFieldMeta('hideIdentity').value)
                                setFieldValue(
                                  'hideIdentity',
                                  !getFieldMeta('hideIdentity').value
                                );
                            }}
                          />
                          <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-primary peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary" />
                        </div>
                      </label>
                    </fieldset>
                    {/* Email */}
                    <h2 className="border-b-2 border-black pb-5 pt-3 text-center text-xl font-normal text-primary">
                      Votre Don
                    </h2>
                    <ul className="mx-auto w-10/12 text-lg">
                      <li className="mb-3 flex justify-between">
                        <span>Don</span>
                        <DisplayAmount value={values.amount} />
                      </li>
                      <li className="relative flex justify-between pb-7">
                        <span>Votre participation au fonctionnement Koud Pouss</span>
                        <DisplayAmount 
                          value={parseFloat(values.koudpoussParticipation || 0)} 
                        />
                        <button
                          type="button"
                          data-hs-overlay="#i-contribute"
                          className="absolute bottom-0 font-medium text-primary"
                        >
                          Modifier
                        </button>
                      </li>
                      <li className="mt-8 flex justify-between border-t-2 border-t-black pt-5">
                        <strong>Total</strong>
                        <DisplayAmount 
                          value={calculateTotal(
                            values.amount,
                            values.koudpoussParticipation
                          )} 
                        />
                      </li>
                    </ul>
                    {/* Section paiement */}
                    <div className="mt-8 border-t-2 border-t-black pt-5">
                      <h2 className="mb-6 text-center text-xl font-normal text-primary">
                        Paiement sécurisé
                      </h2>

                      {!showStripeForm ? (
                        <button
                          type="button"
                          onClick={handlePaymentClick}
                          disabled={loading}
                          className={`relative mx-auto w-full items-start justify-center rounded-lg 
                            bg-primary px-9 py-4 text-base font-bold leading-tight text-white shadow-lg
                            ${loading ? 'cursor-not-allowed opacity-50' : 'duration-300 hover:scale-105'}`}
                        >
                          {loading ? 'Traitement en cours...' : 'Procéder au paiement'}
                        </button>
                      ) : clientSecret ? (
                        <Elements stripe={stripePromise} options={{ clientSecret }}>
                          <StripePaymentForm
                            clientSecret={clientSecret}
                            userData={{
                              email: formRef.current.values.email,
                              first_name: formRef.current.values.first_name,
                              last_name: formRef.current.values.last_name,
                            }}
                            onSuccess={handlePaymentSuccess}
                            onError={(error) => {
                              setMessage([false, 'Une erreur est survenue lors du paiement.']);
                              setShowStripeForm(false);
                            }}
                          />
                        </Elements>
                      ) : null}

                      {message.length > 0 && (
                        <div className={`mt-4 text-center text-sm ${message[0] ? 'text-green-500' : 'text-red-500'}`}>
                          {message[1]}
                        </div>
                      )}

                      <figure className="mx-auto mt-6 w-10/12 items-end sm:flex">
                        <span className="mt-7 flex shrink-0 justify-center text-primary sm:mb-1">
                          <IconLock />
                        </span>
                        <figcaption className="mt-7 text-xs sm:ml-5">
                          Toutes les informations bancaires pour traiter ce
                          paiement sont totalement sécurisées. Grâce au cryptage
                          SSL de vos données bancaires, vous êtes assurés de la
                          fiabilité de vos transactions
                        </figcaption>
                      </figure>
                    </div>
                  </div>
                  {/* Contribution for lakuadra */}
                  <div
                    id="i-contribute"
                    className="hs-overlay fixed left-0 top-0 z-[60] hidden h-full min-h-full w-full overflow-y-auto overflow-x-hidden px-5 pb-5 sm:px-10"
                  >
                    <div className="m-3 flex min-h-full items-center justify-center opacity-0 transition-all hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:my-10 sm:w-full sm:max-w-3xl">
                      <div className="flex flex-col border bg-white pb-7 pt-3 shadow-sm">
                        {/* Close */}
                        <div className="flex items-center justify-end border-b px-4 pb-3">
                          <button
                            type="button"
                            className="hs-dropdown-toggle inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-md text-sm text-primary transition-all hover:text-primary focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white"
                            data-hs-overlay="#i-contribute"
                          >
                            <span className="sr-only">Close</span>
                            <svg
                              className="h-3.5 w-3.5"
                              width="8"
                              height="8"
                              viewBox="0 0 8 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                                fill="currentColor"
                              />
                            </svg>
                          </button>
                        </div>
                        {/* Form */}
                        <section className="mt-3 flex items-center px-3">
                          {/* Why to make a gift */}
                          <figure className="hidden w-1/2 sm:block">
                            <img
                              src={reasonToGive[slide + 2].src}
                              alt={reasonToGive[slide + 2].alt}
                              title="Votre participation compte"
                            />
                          </figure>

                          {/* Heart rating */}
                          <ul className="relative ml-10 mr-10 hidden h-96 w-14 flex-col items-center justify-between font-bold text-primary sm:flex">
                            <li>30%</li>
                            <li className="absolute -left-28 top-[10rem] flex -rotate-90 flex-col items-center justify-center">
                              <input
                                className="custom-slider-bullet h-3 w-80 bg-primary"
                                type="range"
                                min="1"
                                max="30"
                                step="1"
                                value={percentGift}
                                onChange={(e) => {
                                  const newPercent = parseInt(e.target.value, 10);
                                  if (newPercent >= 1 && newPercent <= 30) {
                                    setPercentGift(newPercent);
                                  }
                                }}
                              />
                              <label className="mt-4 rotate-90">
                                {percentGift}% <br />{' '}
                                {((percentGift * parseFloat(values.amount || 0)) / 100).toFixed(2)} €
                              </label>
                            </li>
                            <li>{`${
                              (1 * parseFloat(values.amount)) / 100
                            } €`}</li>
                          </ul>
                          {/* Make a gift */}
                          <aside className="mx-auto max-w-xs grow self-start text-center sm:grow-0">
                            {/* Reason */}
                            <figure className="flex justify-center">
                              <img
                                src={reasonToGive[slide].src}
                                alt={reasonToGive[slide].alt}
                                title="Votre participation compte"
                              />
                            </figure>
                            <span className="my-7 block font-bold text-primary">
                              Mon don <br />
                              {`${values.amount} €`}
                            </span>
                            {/* I contribute */}
                            <div className="mx-auto inline-flex flex-col">
                              <label className="block font-normal">
                                Je choisis le montant de ma participation
                              </label>
                              {/* trigger contribution */}
                              <div className="flex flex-col items-center justify-center text-primary sm:flex-row sm:justify-between">
                                {/* Add reduce contributions buttons */}
                                <fieldset className="mt-7 flex">
                                  <button
                                    type="button"
                                    onClick={() => updateParticipation('add')}
                                    className="border border-primary px-4"
                                  >
                                    +
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      updateParticipation('reduce')
                                    }
                                    className="ml-3 border border-primary px-4 py-1"
                                    aria-label="Réduire le montant de ma participation"
                                  >
                                    <span className="block w-3 border-t border-t-primary" />
                                  </button>
                                </fieldset>
                                {/* Amount to contribute */}
                                <span className="mt-7 border border-primary px-6 py-1">
                                  {((parseFloat(values.amount || 0) * percentGift) / 100).toFixed(2)} €
                                </span>
                              </div>
                            </div>
                            <button
                              type="button"
                              data-hs-overlay="#i-contribute"
                              onClick={() => {
                                handleParticipationValidation(
                                  setFieldValue,
                                  values.amount,
                                  percentGift
                                );
                              }}
                              className="mt-7 block w-full rounded-lg bg-primary px-6 py-4 text-white"
                            >
                              Valider
                            </button>
                          </aside>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Formik>
          </div>
        </div>
      )}

      {!loading && !loadingData && enableToProcessMessage && (
        <div>
          <Header />
          <div className="min-h-[40vh] w-full bg-white px-6 pb-10 pt-6 text-center">
            {enableToProcessMessage}
          </div>
        </div>
      )}
    </div>
  );
};

export default Payment;
