/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import '@splidejs/react-splide/css';
import '@splidejs/react-splide/css/core';
/* eslint-disable unused-imports/no-unused-vars */
import 'video-react/dist/video-react.css'; // import css

import { Splide, SplideSlide } from '@splidejs/react-splide';
import Activity from 'components/Activity';
import Banner from 'components/Banner';
import React from 'react';
import { Link } from 'react-router-dom';

const Ranking = () => {
  const BASE_PATH = `/assets/`;
  const activities = [
    {
      image: 'children_humanity.webp',
      title: 'Solidarité',
      subTitle: (
        <>
          Soutenons l’association <br /> Maracca
        </>
      ),
      rate: '70',
      success: (
        <>
          <span className="text-emerald-600">10000 €</span> collecté sur 7000
        </>
      ),
      backgroundColor: 'bg-emerald-600',
      rank: 'rank_one.png',
    },
    {
      image: 'animal.jpg',
      title: 'Animalière',
      subTitle: <>Soutenons Alfred</>,
      rate: '70',
      success: (
        <>
          <span className="text-blue-400">10000 €</span> collecté sur 7000
        </>
      ),
      backgroundColor: 'bg-blue-400',
      rank: 'rank_two.png',
    },
    {
      image: 'wedding.jpeg',
      title: 'Mariage',
      subTitle: (
        <>
          Soutenons l’association <br /> Maracca
        </>
      ),
      rate: '70',
      success: (
        <>
          <span className="text-secondary">10000 €</span> collecté sur 7000
        </>
      ),
      backgroundColor: 'bg-secondary',
      rank: 'rank_three.png',
    },
    {
      image: 'children_humanity.webp',
      title: 'Solidarité',
      subTitle: (
        <>
          Soutenons l’association <br /> Maracca
        </>
      ),
      rate: '70',
      success: (
        <>
          <span className="text-emerald-600">10000 €</span> collecté sur 7000
        </>
      ),
      backgroundColor: 'bg-emerald-600',
      rank: 'rank_four.png',
    },
    {
      image: 'children_humanity.webp',
      title: 'Solidarité',
      subTitle: (
        <>
          Soutenons l’association <br /> Maracca
        </>
      ),
      rate: '70',
      success: (
        <>
          <span className="text-emerald-600">10000 €</span> collecté sur 7000
        </>
      ),
      backgroundColor: 'bg-emerald-600',
      rank: 'rank_five.png',
    },
    {
      image: 'animal.jpg',
      title: 'Animalière',
      subTitle: <>Soutenons Alfred</>,
      rate: '70',
      success: (
        <>
          <span className="text-blue-400">10000 €</span> collecté sur 7000
        </>
      ),
      backgroundColor: 'bg-blue-400',
      rank: 'rank_six.png',
    },
    {
      image: 'wedding.jpeg',
      title: 'Mariage',
      subTitle: (
        <>
          Soutenons l’association <br /> Maracca
        </>
      ),
      rate: '70',
      success: (
        <>
          <span className="text-secondary">10000 €</span> collecté sur 7000
        </>
      ),
      backgroundColor: 'bg-secondary',
      rank: 'rank_seven.png',
    },
    {
      image: 'children_humanity.webp',
      title: 'Solidarité',
      subTitle: (
        <>
          Soutenons l’association <br /> Maracca
        </>
      ),
      rate: '70',
      success: (
        <>
          <span className="text-emerald-600">10000 €</span> collecté sur 7000
        </>
      ),
      backgroundColor: 'bg-emerald-600',
      rank: 'rank_eight.png',
    },
  ];

  return (
    <div className="container mx-auto max-w-7xl md:px-8 lg:px-24">
      <Banner
        image="superheroes.png"
        currentPage={0}
        headerMessageContainer="items-end xl:ml-auto xl:mr-0 lg:text-left"
        bannerClass="h-banner-large"
      />
      <div className="bg-white py-16">
        <Link to="/ma-collecte-populaire">
          <span className="mx-10 block text-red-500 underline">
            Retour sur la tirelire solidaire
          </span>
        </Link>
        <h2 className="mt-16 mb-10 px-3 text-center text-2xl">
          Évènement de la&nbsp;
          <span className="text-primary">vie</span>
        </h2>
        <ul className="mx-10 hidden justify-between lg:flex">
          {activities.slice(0, 4).map((activity) => (
            <li key={activity.title} className="mr-5 w-full max-w-xs">
              <Activity data={activity} />
            </li>
          ))}
        </ul>
        <ul className="mx-10 mt-10 hidden justify-between lg:flex">
          {activities.slice(4, 8).map((activity) => (
            <li key={activity.title} className="mr-5 w-full max-w-xs">
              <Activity data={activity} />
            </li>
          ))}
        </ul>
        <section className="lg:hidden">
          <Splide
            options={{
              perPage: 1,
              perMove: 1,
              arrows: true,
              pagination: false,
              autoplay: false,
              gap: 34,
              rewind: false,
              type: 'slide',
              breakpoints: {
                640: {
                  perPage: 1,
                  perMove: 1,
                  gap: 10,
                },
                980: {
                  perPage: 2,
                  perMove: 2,
                  gap: 10,
                },
                1024: {
                  perPage: 3,
                  perMove: 3,
                  gap: 10,
                },
                1280: {
                  perPage: 4,
                  perMove: 4,
                  gap: 10,
                },
              },
            }}
          >
            {activities.map((activity) => (
              <SplideSlide key={activity.title} className="w-full max-w-xs">
                <Activity data={activity} />
              </SplideSlide>
            ))}
          </Splide>
        </section>
      </div>
    </div>
  );
};

export default Ranking;
