/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
import 'video-react/dist/video-react.css'; // import css

import parse from 'html-react-parser';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import Banner from '../components/Banner';

const Pricing = () => {
  const BASE_PATH = `/assets/`;
  const [step, setStep] = useState(1);
  const options = [
    {
      text: '1 Lancer sa collecte avec Koud Pouss est totalement GRATUIT. <br/>Vous collectez et dépensez 100 % des fonds collectés !',
      src: '/assets/launch.webp',
    },
    {
      text: '2 Vous pouvez lancer un nombre illimité de collectes et fractionner vos demandes de retraits ! <br/>Vous êtes libres de choisir le montant de vos virements même si vos objectifs ne sont pas atteints.',
      src: '/assets/launch-2.webp',
    },
    {
      text: '3 Lorsque vous estimez que vos objectifs sont atteints , dépensez votre collecte. <br/>Les fonds seront virés sur votre compte bancaire sous 48 à 72 h jours ouvrés.',
      src: '/assets/launch-3.webp',
    },
  ];
  return (
    <div className="container mx-auto max-w-7xl md:px-8 lg:px-24">
      <Helmet title="Bienvenue sur KOUDPOUSS" />
      <Banner
        image="famille.webp"
        currentPage={0}
        headerMessageContainer="items-end xl:ml-auto xl:mr-0 lg:text-left"
        bannerClass="h-banner-large"
      />
      <div className="bg-white pb-14">
        <h1 className="px-3 pt-16 text-center text-2xl font-light text-black">
          <span className="text-primary">Koud Pouss</span>, la plateforme de
          financement participatif qui vous ressemble
        </h1>
        <p className="mt-7 px-3 text-center text-3xl font-light text-black">
          0 <span className="text-primary">Frais</span> cachés - 0{' '}
          <span className="text-primary">commissions</span>
        </p>
        <section className="mx-auto max-w-5xl px-6">
          <ul className="relative mb-10 mt-16 flex justify-between before:absolute before:top-1/2 before:-z-0 before:w-full before:border-b-2 before:border-[#E3832B]">
            <li
              className="z-0 cursor-pointer bg-white pr-4"
              onClick={() => setStep(1)}
            >
              <span
                className={`flex h-16 w-16 items-center justify-center rounded-full
            ${
              step === 1
                ? 'bg-[#E3832B] text-white'
                : 'border border-[#E3832B] bg-white'
            }`}
              >
                1
              </span>
            </li>
            <li
              className="z-0 cursor-pointer bg-white px-4"
              onClick={() => setStep(2)}
            >
              <span
                className={`flex h-16 w-16 items-center justify-center rounded-full
            ${
              step === 2
                ? 'bg-[#E3832B] text-white'
                : 'border border-[#E3832B] bg-white'
            }`}
              >
                2
              </span>
            </li>
            <li
              className="z-0 cursor-pointer bg-white pl-4"
              onClick={() => setStep(3)}
            >
              <span
                className={`flex h-16 w-16 items-center justify-center rounded-full
            ${
              step === 3
                ? 'bg-[#E3832B] text-white'
                : 'border border-[#E3832B] bg-white'
            }`}
              >
                3
              </span>
            </li>
          </ul>
          <figure className="mb-16 items-center justify-between sm:flex">
            <figcaption className="mr-10 max-w-3xl text-2xl">
              {parse(options[step - 1].text)}
            </figcaption>
            <img
              src={options[step - 1].src}
              className="mx-auto mt-6 w-96 sm:mt-0"
              alt="Lancer la collecte"
              title="Lancer la collecte ..."
            />
          </figure>
        </section>
        <div
          className="bg-right relative flex items-end justify-center bg-no-repeat px-3 before:absolute before:h-full before:w-full before:bg-white lg:h-[19rem] lg:bg-cover lg:before:bg-transparent"
          style={{
            backgroundImage: `url(${BASE_PATH}montagne.7622a31d1e56afaab276.webp`,
          }}
        >
          <div className="relative z-10 mb-3 flex flex-col gap-8 lg:flex-row lg:gap-16">
            <figure className="lg:w-1/2">
              <img
                src={`${BASE_PATH}girl_right_parrot.webp`}
                alt="girl_right_parrot"
                className="w-full -translate-y-0 scale-100 lg:-translate-y-[0.5rem] lg:scale-110"
              />
            </figure>
            <aside className="card-gradient relative mx-auto max-w-md self-start rounded-3xl px-10 py-8 text-center text-xl text-white before:absolute before:left-[-20px] before:border-y-[10px] before:border-y-transparent before:border-r-yellow-500 sm:px-24 md:-bottom-16 lg:mx-0 before:lg:border-r-[30px]">
              <p>
                Alors prêt ? Propulsez votre initiative dès maintenant et
                connectez votre Team !
              </p>
            </aside>
          </div>
        </div>
        <h2 className="mb-14 mt-8 px-3 text-center text-2xl font-light text-black">
          Notre plateforme a été pensée et conçue de façon intuitive, <br /> l’
          <span className="text-primary">Koud Pouss</span> innove.
        </h2>
        <ul className="mx-auto max-w-5xl space-y-10 px-6 text-xl">
          <li>
            Seuls les frais bancaires des émetteurs de paiement qui permettent
            et assurent la sécurité de chaque transaction seront prélevés :
          </li>
          <li>
            Paiements cb{' '}
            <span className="font-medium text-[#C44683]">Stripe</span> <br />{' '}
          </li>
          <li>
            Vous avez la possibilité d’effectuer votre don pour la cause qui
            vous tient à cœur en toute sécurité par carte bancaire.
          </li>
          <li className="flex items-start before:mr-5 before:mt-2.5 before:h-2 before:w-2 before:rounded-full before:bg-primary">
            Vous n’avez pas besoin de créer un compte pour supporter une
            collecte .
          </li>
          <li className="flex items-start before:mr-5 before:mt-2.5 before:h-2 before:w-2 before:rounded-full before:bg-primary">
            Vous êtes libre de rendre vos informations et votre don anonyme.
          </li>
          <li className="flex items-start before:mr-5 before:mt-2.5 before:h-2 before:w-2 before:rounded-full before:bg-primary">
            Vous pouvez laisser un message de soutien sur la collecte pour
            laquelle vous avez décidé de contribuer.
          </li>
          <li className="flex items-start before:mr-5 before:mt-2.5 before:h-2 before:w-2 before:rounded-full before:bg-primary">
            Suite à votre don, vous recevrez un mail de confirmation pour votre
            participation .
          </li>
        </ul>
        <h2 className="my-14 px-3 text-center text-2xl font-light text-black">
          La <span className="text-primary">solidarité</span> est notre{' '}
          <span className="text-primary">cœur</span> de métier
        </h2>
        <div className="overflow-hidden">
          <figure className="relative mb-14 bg-primary pl-5 after:absolute after:-bottom-5 after:left-0 after:border-r-[70vw] after:border-t-[20px] after:border-r-transparent after:border-t-orange-400">
            <img src="/assets/wink.webp" alt="" title="" className="w-full" />
          </figure>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
