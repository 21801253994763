/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

const Header = ({ currentPage }) => {
  const location = useLocation();
  const path = location.pathname;
  const [active, setActive] = useState(currentPage);
  const [index, set] = useState(0);
  const listMenu = [
    {
      name: localStorage.getItem('persist:root')
        ? 'Tableau de bord'
        : 'Accueil',
      link: localStorage.getItem('persist:root') ? '/tableau-de-bord' : '/',
      items: [],
    },
    {
      name: 'Lancer une collecte',
      link: '/lancer-une-collecte',
      items: [],
    },
    {
      name: 'La Tirelire Solidaire',
      link: '/tirelire',
      items: [
        {
          name: 'Comment ça marche',
          link: '/comment-ca-marche',
        },
        {
          name: 'Ta collecte populaire',
          link: '/ma-collecte-populaire',
        },
      ],
    },
    {
      name: 'Comment débuter',
      link: '/comment-debuter',
      items: [],
    },
  ];

  const goToPage = (idx, route) => {
    setActive(idx);
  };

  const logOut = () => {
    localStorage.removeItem('persist:root');
    window.location.href = '/';
  };

  return (
    <header className="relative border-b border-gray-300/5 text-black">
      <section className="flex w-full flex-col">
        <nav
          className="relative z-50 mx-auto w-full space-x-4 bg-white px-4 lg:flex lg:items-center lg:justify-between"
          aria-label="Global"
        >
          <div className="flex w-full items-center justify-between lg:w-auto">
            <a href="/">
              <figure className="mr-auto flex w-24 cursor-pointer items-center md:mt-0 lg:mt-0">
                <img
                  src="/logo.png"
                  className="mr-3 inline-flex h-24"
                  alt="Koudpouss"
                />
              </figure>
            </a>
            <div className="lg:hidden">
              <button
                type="button"
                className="hs-collapse-toggle inline-flex items-center justify-center gap-2 rounded-md bg-white p-2 align-middle text-sm font-medium text-gray-700 shadow-inner transition-all hover:bg-gray-50 focus:outline-none focus:ring-0 focus:ring-offset-transparent"
                data-hs-collapse="#navbar-collapse-with-animation"
                aria-controls="navbar-collapse-with-animation"
                aria-label="Toggle navigation"
              >
                <svg
                  className="h-5 w-5 hs-collapse-open:hidden"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                  />
                </svg>
                <svg
                  className="hidden h-4 w-4 hs-collapse-open:block"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </button>
            </div>
          </div>
          <div
            id="navbar-collapse-with-animation"
            className="hs-collapse hidden grow basis-full overflow-hidden transition-all duration-300 lg:block"
          >
            <div className="mt-5 flex flex-col gap-5 lg:mt-0 lg:flex-row lg:items-center lg:justify-end lg:pl-5">
              {listMenu.map((item, itemId) => {
                return (
                  <ul key={`b_${item.name}_${itemId}`}>
                    {item?.items?.length <= 0 && (
                      <li
                        className={`cursor-pointer ml-3 !border-solid !py-2 text-xs
                        ${active === itemId ? 'text-primary' : ''}`}
                      >
                        <a
                          className="flex items-center font-medium hover:text-primary"
                          href={item.link}
                        >
                          {item.name}
                        </a>
                      </li>
                    )}
                    {item?.items?.length > 0 && (
                      <li
                        className="hs-dropdown ml-3 cursor-pointer border-solid py-2 text-xs [--strategy:static] [--adaptive:none] lg:[--strategy:fixed]"
                        key={`bi_${item.name}_${itemId}`}
                      >
                        <a
                          id="hs-mega-menu-basic-dr"
                          className="flex items-center font-medium hover:text-primary"
                        >
                          {item.name}
                        </a>

                        <div className="hs-dropdown-menu lg:tri-right left-top relative top-full z-10 hidden opacity-0 transition-[opacity,margin] duration-[0.1ms] before:absolute before:-top-5 before:left-0 before:h-5 before:w-full hs-dropdown-open:opacity-100 md:max-w-md lg:w-48 lg:shadow-md lg:duration-[150ms]">
                          <div className="relative">
                            <div className="absolute inset-0 bg-white lg:bg-opacity-[16%] lg:brightness-[15%]" />
                            <ul className="relative z-50 p-2 lg:backdrop-blur-[30px]">
                              {item.items.map((i, id) => (
                                <li
                                  className={`hs-dropdown sm:[--strategy:absolute]" relative [--strategy:static] [--adaptive:none]
                                ${active === id ? 'text-primary' : ''}`}
                                  key={`bd_${i.name}_${id}`}
                                >
                                  <a
                                    href={i.link}
                                    className="flex items-center gap-x-3.5 rounded-md py-2 px-3 text-sm text-white focus:ring-0 lg:hover:text-primary"
                                  >
                                    {i.name}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </li>
                    )}
                  </ul>
                );
              })}
              <div className="flex flex-row justify-between space-x-3 pr-4 lg:hidden">
                <a
                  href="https://www.facebook.com/koudpouss"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex h-12 w-12 items-center justify-center rounded-full bg-light"
                >
                  <img src="/assets/facebook.png" alt="Facebook" />
                </a>
                <a
                  href="https://www.instagram.com/koudpouss/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex h-12 w-12 items-center justify-center rounded-full bg-light"
                >
                  <img src="/assets/instagram.png" alt="Instagram" />
                </a>
                <a
                  href="https://www.linkedin.com/company/koudpouss/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex h-12 w-12 items-center justify-center rounded-full bg-light"
                >
                  <img src="/assets/linkedin.png" alt="Linkedin" />
                </a>
              </div>
            </div>
          </div>
          <div className="hs-dropdown relative hidden [--strategy:static] [--adaptive:none] sm:[--strategy:absolute] lg:block">
            {localStorage.getItem('persist:root') && (
              <button
                type="button"
                className="button-glassmorphism -mt-6 flex items-center py-4 px-6 font-medium text-white xl:px-12"
                aria-label="Menu utilisateur"
              >
                <i className="fas fa-user-circle text-5xl" aria-hidden="true" />
                <i className="fas fa-chevron-down ml-2" aria-hidden="true" />
              </button>
            )}

            {!localStorage.getItem('persist:root') && (
              <a href="/se-connecter">
                <button
                  type="button"
                  className="button-glassmorphism -mt-6 flex items-center py-4 px-6 font-medium text-white"
                >
                  Inscription/Connexion
                </button>
              </a>
            )}

            {localStorage.getItem('persist:root') && (
              <div className="hs-dropdown-menu w-fill relative top-full z-10 hidden opacity-0 transition-[opacity,margin] duration-[0.1ms] before:absolute before:-top-5 before:left-0 before:h-5 before:w-full hs-dropdown-open:opacity-100 md:max-w-sm lg:w-48 lg:shadow-md lg:duration-[150ms]">
                <div className="relative">
                  <div className="absolute inset-0 bg-white bg-opacity-[16%] brightness-[15%]" />
                  <ul className="relative z-50 p-2 backdrop-blur-[30px]">
                    <li>
                      <a
                        className="flex items-center gap-x-3.5 rounded-md py-2 px-3 text-sm text-white focus:ring-0 lg:hover:text-primary"
                        href="/mon-profil"
                      >
                        Mon profil
                      </a>
                    </li>
                    <li>
                      <a
                        className="flex items-center gap-x-3.5 rounded-md py-2 px-3 text-sm text-white focus:ring-0 lg:hover:text-primary"
                        href="/mes-collectes"
                      >
                        Mes collectes
                      </a>
                    </li>
                    <li>
                      <button
                        type="button"
                        className="flex items-center gap-x-3.5 rounded-md py-2 px-3 text-sm text-white focus:ring-0 lg:hover:text-primary"
                        onClick={logOut}
                      >
                        Déconnexion
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
          <div className="mb-4 hidden flex-row justify-between space-x-3 pr-4 xl:flex">
            <a
              href="https://www.facebook.com/koudpouss"
              target="_blank"
              rel="noopener noreferrer"
              className="flex h-8 w-8 items-center justify-center rounded-full bg-light"
            >
              <img src="/assets/facebook.png" alt="Facebook" />
            </a>
            <a
              href="https://www.instagram.com/koudpouss/"
              target="_blank"
              rel="noopener noreferrer"
              className="flex h-8 w-8 items-center justify-center rounded-full bg-light"
            >
              <img src="/assets/instagram.png" alt="Instagram" />
            </a>
            <a
              href="https://www.linkedin.com/company/koudpouss/"
              target="_blank"
              rel="noopener noreferrer"
              className="flex h-8 w-8 items-center justify-center rounded-full bg-light"
            >
              <img src="/assets/linkedin.png" alt="Linkedin" />
            </a>
          </div>
        </nav>
      </section>
    </header>
  );
};

Header.propTypes = {
  currentPage: PropTypes.number,
};

export default Header;
