import React from 'react';

const IconHeart = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      version="1.1"
      viewBox="0 0 700 700"
      className="mx-auto"
    >
      <g>
        <path
          fill={color}
          d="m630 178.64v44.801c-0.4375 0.98828-0.77344 2.0195-1.0078 3.0781-2.2461 17.875-7.3516 35.27-15.121 51.52-17.121 35.402-40.062 67.676-67.871 95.48-56.727 59.023-122.02 106.91-191.52 149.46-2.457 1.8945-5.8867 1.8945-8.3438 0-55.859-33.359-108.37-72.027-156.8-115.47-34.75-30.609-64.973-66-89.77-105.11-13.637-21.48-22.82-45.48-27.008-70.578-4.1836-25.098-3.2812-50.781 2.6484-75.523 25.426-113.07 166.21-159.27 253.91-83.496 7.5039 6.4414 14.113 13.945 21.617 21.336h-0.003907c0.46094-0.77344 0.96875-1.5234 1.5117-2.2422 39.199-43.848 87.641-63.449 145.6-53.703 63.559 10.641 105.22 48.609 125.5 109.7 2.7852 10.117 5.0117 20.383 6.6641 30.746z"
        />
      </g>
    </svg>
  );
};

IconHeart.propTypes = {
  size: IconHeart.string,
  color: IconHeart.string,
};
export default IconHeart;
