/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable simple-import-sort/imports */
/* eslint-disable import/no-extraneous-dependencies */
import {
  addBankAccount,
  deleteAccount,
  getMyProfile,
  updateUser,
  validateProfile,
  getBankAccount,
  updateBankAccount,
} from 'api/user';
import countries from 'assets/countries.json';
import Header from 'components/Header';
import IconTrash from 'components/icons/IconTrash';
import SubHeader from 'components/SubHeader';
import { fr } from 'date-fns/locale';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Calendar } from 'react-date-range';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import useOutsideClick from 'utils/useOutsideClick';
import * as Yup from 'yup';
import { useLoadScript, Autocomplete } from '@react-google-maps/api';

const PublicInformationSchema = Yup.object().shape({
  public_name: Yup.string().required('Vous devez renseigner un nom public'),
  biography: Yup.string().max(
    500,
    'La bio ne peut pas dépasser 500 caractères'
  ),
});
const PrivateInformationSchema = Yup.object().shape({
  lastName: Yup.string().required('Veuillez saisir votre nom'),
  firstName: Yup.string().required('Veuillez saisir votre prénom'),
  birthDate: Yup.string().required('Veuillez saisir votre date de naissance'),
  email: Yup.string()
    .email("L'adresse email n'est pas valide")
    .required('Veuillez saisir votre adresse email'),
  address: Yup.string().required('Veuillez saisir votre adresse'),
  country: Yup.string().required('Choisissez votre pays'),
  city: Yup.string().required('Choisissez votre ville'),
  zipCode: Yup.string().required('Renseignez votre code postal'),
});
const UpdatePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required(
    'Veuillez entrer votre ancien mot de passe'
  ),
  newPassword: Yup.string()
    .required('Veuillez entrer un nouveau mot de passe')
    .min(8, 'Le mot de passe doit contenir au moins 8 caractères')
    .matches(/[A-Z]/, 'Le mot de passe doit contenir au moins une majuscule')
    .matches(
      /[+=!@#$%^&*]/,
      'Le mot de passe doit contenir au moins un caractère spécial'
    ),
  confirmPassword: Yup.string()
    .required('Veuillez confirmer le nouveau mot de passe')
    .oneOf(
      [Yup.ref('newPassword'), null],
      'Les mots de passe ne correspondent pas'
    ),
});
const BankAccountSchema = Yup.object().shape({
  bank_name: Yup.string().required('Le nom de la banque est requis'),
  iban: Yup.string().required("L'IBAN est requis"),
  bic: Yup.string().required('Le BIC est requis'),
  rib: Yup.mixed().when(['selectedDocType', 'existingRib'], {
    is: (selectedDocType, existingRib) =>
      selectedDocType === 'rib' && !existingRib,
    then: (schema) => schema.required('Le RIB est requis'),
    otherwise: (schema) => schema.nullable(),
  }),
  bank_statement: Yup.mixed().when(
    ['selectedDocType', 'existingBankStatement'],
    {
      is: (selectedDocType, existingBankStatement) =>
        selectedDocType === 'bank_statement' && !existingBankStatement,
      then: (schema) => schema.required('Le relevé bancaire est requis'),
      otherwise: (schema) => schema.nullable(),
    }
  ),
  selectedDocType: Yup.string().required(
    'Veuillez sélectionner un type de document'
  ),
});

const BankAccountUpload = () => {
  const [message, setMessage] = useState({ type: '', text: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedDocType, setSelectedDocType] = useState('rib');
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [bankAccount, setBankAccount] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const fileInputRef = useRef(null);
  const [existingDocuments, setExistingDocuments] = useState({
    rib: '',
    bank_statement: '',
  });

  useEffect(() => {
    const fetchBankAccount = async () => {
      try {
        const response = await getBankAccount();
        if (response.data.data?.length > 0) {
          const account = response.data.data[0];
          setBankAccount(account);
          setExistingDocuments({
            rib: account.rib || '',
            bank_statement: account.bank_statement || '',
          });
        }
      } catch (error) {
        setMessage({
          type: 'error',
          text: 'Erreur lors de la récupération des informations bancaires',
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchBankAccount();
  }, []);

  const handleBoxClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (docType, file, setFieldValue) => {
    if (file) {
      setFieldValue(docType, file);
      setUploadedFileName(file.name);
    }
  };

  const renderExistingDocuments = () => {
    if (!bankAccount) return null;

    return (
      <div className="mt-4 rounded-lg border border-gray-200 p-3 sm:mt-6 sm:p-4">
        <h4 className="mb-3 font-medium text-gray-700 sm:mb-4">
          Documents enregistrés
        </h4>
        <div className="space-y-2 sm:space-y-3">
          {bankAccount.rib && (
            <div className="flex flex-col justify-between gap-2 sm:flex-row sm:items-center">
              <span className="text-sm text-gray-600">RIB</span>
              <a
                href={bankAccount.rib}
                target="_blank"
                rel="noopener noreferrer"
                className="text-sm text-primary hover:underline"
              >
                Voir le document
              </a>
            </div>
          )}
          {bankAccount.bank_statement && (
            <div className="flex flex-col justify-between gap-2 sm:flex-row sm:items-center">
              <span className="text-sm text-gray-600">Relevé bancaire</span>
              <a
                href={bankAccount.bank_statement}
                target="_blank"
                rel="noopener noreferrer"
                className="text-sm text-primary hover:underline"
              >
                Voir le document
              </a>
            </div>
          )}
        </div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="flex justify-center p-4 sm:p-8">
        <div className="h-8 w-8 animate-spin rounded-full border-2 border-primary border-t-transparent" />
      </div>
    );
  }

  return (
    <div className="p-4 sm:p-8">
      {message.text && (
        <div
          className={`mb-4 rounded-lg p-3 sm:p-4 ${
            message.type === 'success'
              ? 'bg-green-100 text-green-700'
              : 'bg-red-100 text-red-700'
          }`}
        >
          <div className="flex items-center">
            {message.type === 'success' ? (
              <svg
                className="mr-2 h-4 w-4 sm:h-5 sm:w-5"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
            ) : (
              <svg
                className="mr-2 h-4 w-4 sm:h-5 sm:w-5"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clipRule="evenodd"
                />
              </svg>
            )}
            <p className="text-sm sm:text-base">{message.text}</p>
          </div>
        </div>
      )}

      <Formik
        initialValues={{
          bank_name: bankAccount?.bank_name || '',
          iban: bankAccount?.iban || '',
          bic: bankAccount?.bic || '',
          rib: null,
          bank_statement: null,
          selectedDocType: 'rib',
          existingRib: !!bankAccount?.rib,
          existingBankStatement: !!bankAccount?.bank_statement,
        }}
        validationSchema={BankAccountSchema}
        onSubmit={async (values, { resetForm }) => {
          try {
            setIsSubmitting(true);
            const formData = new FormData();
            formData.append('bank_name', values.bank_name);
            formData.append('iban', values.iban);
            formData.append('bic', values.bic);
            formData.append('selectedDocType', values.selectedDocType);

            if (values.selectedDocType === 'rib' && values.rib) {
              formData.append('rib', values.rib);
            } else if (
              values.selectedDocType === 'bank_statement' &&
              values.bank_statement
            ) {
              formData.append('bank_statement', values.bank_statement);
            }

            if (bankAccount) {
              await updateBankAccount(bankAccount.id, formData);
              setMessage({
                type: 'success',
                text: 'Informations bancaires mises à jour avec succès!',
              });
            } else {
              await addBankAccount(formData);
              setMessage({
                type: 'success',
                text: 'Compte bancaire ajouté avec succès!',
              });
            }

            setTimeout(() => {
              resetForm();
              window.location.reload();
            }, 2000);
          } catch (error) {
            setMessage({
              type: 'error',
              text:
                error.response?.data?.message || "Une erreur s'est produite",
            });
          } finally {
            setIsSubmitting(false);
          }
        }}
      >
        {({ errors, touched, setFieldValue, values }) => (
          <Form className="space-y-4 sm:space-y-6">
            <h3 className="text-lg font-semibold text-gray-800 sm:text-xl">
              {bankAccount
                ? 'Modifier les informations bancaires'
                : 'Ajouter un compte bancaire'}
            </h3>

            <div className="space-y-3 sm:space-y-4">
              <div>
                <label className="mb-1 block text-sm font-medium text-gray-700">
                  Nom de la banque
                </label>
                <Field
                  name="bank_name"
                  type="text"
                  className="w-full rounded-lg border border-gray-300 p-2.5 sm:p-3"
                  placeholder="Nom de votre banque"
                />
                {errors.bank_name && touched.bank_name && (
                  <div className="mt-1 text-xs text-red-500 sm:text-sm">
                    {errors.bank_name}
                  </div>
                )}
              </div>

              <div>
                <label className="mb-1 block text-sm font-medium text-gray-700">
                  IBAN
                </label>
                <Field
                  name="iban"
                  type="text"
                  className="w-full rounded-lg border border-gray-300 p-2.5 sm:p-3"
                  placeholder="FR76 XXXX XXXX XXXX XXXX XXXX XXX"
                />
                {errors.iban && touched.iban && (
                  <div className="mt-1 text-xs text-red-500 sm:text-sm">
                    {errors.iban}
                  </div>
                )}
              </div>

              <div>
                <label className="mb-1 block text-sm font-medium text-gray-700">
                  BIC
                </label>
                <Field
                  name="bic"
                  type="text"
                  className="w-full rounded-lg border border-gray-300 p-2.5 sm:p-3"
                  placeholder="XXXXXXPPXXX"
                />
                {errors.bic && touched.bic && (
                  <div className="mt-1 text-xs text-red-500 sm:text-sm">
                    {errors.bic}
                  </div>
                )}
              </div>

              <div className="space-y-2">
                <div className="flex flex-col gap-2 sm:flex-row sm:gap-4">
                  <label className="flex items-center">
                    <Field
                      type="radio"
                      name="selectedDocType"
                      value="rib"
                      className="mr-2"
                      onChange={(e) => {
                        setFieldValue('selectedDocType', 'rib');
                        setSelectedDocType('rib');
                        setFieldValue('bank_statement', null);
                        setUploadedFileName('');
                      }}
                    />
                    <span className="text-sm sm:text-base">Charger un RIB</span>
                  </label>
                  <label className="flex items-center">
                    <Field
                      type="radio"
                      name="selectedDocType"
                      value="bank_statement"
                      className="mr-2"
                      onChange={(e) => {
                        setFieldValue('selectedDocType', 'bank_statement');
                        setSelectedDocType('bank_statement');
                        setFieldValue('rib', null);
                        setUploadedFileName('');
                      }}
                    />
                    <span className="text-sm sm:text-base">
                      Charger un relevé de compte bancaire
                    </span>
                  </label>
                </div>

                <button
                  type="button"
                  className="mt-2 w-full cursor-pointer rounded-lg border-2 border-dashed border-gray-300 p-4 text-center transition-colors hover:border-primary/50 hover:bg-gray-50 sm:p-8"
                  onDrop={(e) => {
                    e.preventDefault();
                    const file = e.dataTransfer.files[0];
                    if (file && file.type === 'application/pdf') {
                      setFieldValue(selectedDocType, file);
                      setUploadedFileName(file.name);
                    }
                  }}
                  onDragOver={(e) => e.preventDefault()}
                  onClick={handleBoxClick}
                >
                  <input
                    ref={fileInputRef}
                    type="file"
                    className="hidden"
                    accept={
                      selectedDocType === 'bank_statement'
                        ? '.pdf,.jpg,.jpeg,.png'
                        : '.pdf'
                    }
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file) {
                        if (selectedDocType === 'bank_statement') {
                          if (
                            file.type === 'application/pdf' ||
                            file.type.startsWith('image/')
                          ) {
                            handleFileChange(
                              selectedDocType,
                              file,
                              setFieldValue
                            );
                          }
                        } else if (file.type === 'application/pdf') {
                          handleFileChange(
                            selectedDocType,
                            file,
                            setFieldValue
                          );
                        }
                      }
                    }}
                  />

                  {uploadedFileName ? (
                    <div className="flex flex-col items-center gap-2">
                      <svg
                        className="h-8 w-8 text-primary sm:h-12 sm:w-12"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                      <p className="text-xs font-medium text-gray-900 sm:text-sm">
                        {uploadedFileName}
                      </p>
                      <button
                        type="button"
                        className="text-xs text-red-500 hover:text-red-700"
                        onClick={(e) => {
                          e.stopPropagation();
                          setFieldValue(selectedDocType, null);
                          setUploadedFileName('');
                        }}
                      >
                        Supprimer le fichier
                      </button>
                    </div>
                  ) : (
                    <>
                      <svg
                        className="mx-auto h-8 w-8 text-gray-400 sm:h-12 sm:w-12"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                        />
                      </svg>
                      <p className="mt-2 text-xs text-gray-600 sm:mt-4 sm:text-sm">
                        {selectedDocType === 'bank_statement'
                          ? 'Cliquez ou glissez-déposez votre fichier ici'
                          : 'Cliquez ou glissez-déposez votre fichier PDF ici'}
                      </p>
                      <p className="mt-1 text-xs text-gray-500">
                        {selectedDocType === 'bank_statement'
                          ? 'Formats acceptés: PDF, JPG, JPEG, PNG'
                          : 'Format accepté: PDF uniquement'}
                      </p>
                    </>
                  )}
                </button>

                {errors.selectedDocType && touched.selectedDocType && (
                  <div className="mt-1 text-xs text-red-500 sm:text-sm">
                    {errors.selectedDocType}
                  </div>
                )}
                {((values.selectedDocType === 'rib' &&
                  errors.rib &&
                  touched.rib) ||
                  (values.selectedDocType === 'bank_statement' &&
                    errors.bank_statement &&
                    touched.bank_statement)) && (
                  <div className="mt-1 text-xs text-red-500 sm:text-sm">
                    {values.selectedDocType === 'rib'
                      ? errors.rib
                      : errors.bank_statement}
                  </div>
                )}
              </div>
            </div>

            <div className="flex justify-end">
              <button
                type="submit"
                disabled={isSubmitting}
                className={`button-glassmorphism relative mx-auto w-fit items-start justify-center rounded-full px-6 py-3 text-sm font-bold leading-tight text-white sm:px-9 sm:py-4 sm:text-base
                  ${
                    isSubmitting
                      ? 'cursor-not-allowed opacity-50'
                      : 'duration-300 hover:scale-105 hover:bg-primary/90'
                  }`}
              >
                {(() => {
                  if (isSubmitting) {
                    return (
                      <div className="flex items-center">
                        <svg
                          className="mr-2 h-4 w-4 animate-spin"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                            fill="none"
                          />
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          />
                        </svg>
                        {bankAccount ? 'Mettre à jour' : 'Valider'}
                      </div>
                    );
                  }
                  return bankAccount ? 'Mettre à jour' : 'Valider';
                })()}
              </button>
            </div>
          </Form>
        )}
      </Formik>
      {renderExistingDocuments()}
    </div>
  );
};

const AddressAutocomplete = ({ field, form }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
  });

  const [autocomplete, setAutocomplete] = useState(null);

  const onLoad = (autoComplete) => {
    setAutocomplete(autoComplete);
  };

  const handlePlaceSelect = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (!place.geometry && !place.formatted_address) {
        return;
      }

      const address = place.formatted_address || '';
      form.setFieldValue(field.name, address, true);
    }
  };

  if (!isLoaded) return <div>Chargement...</div>;

  return (
    <Autocomplete onLoad={onLoad} onPlaceChanged={handlePlaceSelect}>
      <input
        {...field}
        type="text"
        placeholder="Entrez votre adresse"
        className="relative w-full rounded border border-gray-300 bg-white p-3.5"
      />
    </Autocomplete>
  );
};
const DocumentUpload = ({ onSubmit }) => {
  const [step, setStep] = useState(1);
  const [preview, setPreview] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState({ type: '', text: '' });

  const documentTypes = [
    { value: 'cni', label: "Carte d'identité" },
    { value: 'driving_license', label: 'Permis de conduire' },
    { value: 'passport', label: 'Passeport' },
    { value: 'residence_permit', label: 'Titre de séjour' },
  ];

  const getStepLabel = (stepNumber) => {
    switch (stepNumber) {
      case 1:
        return 'Contact';
      case 2:
        return 'Documents';
      case 3:
        return 'Validation';
      default:
        return '';
    }
  };

  const handleFileChange = (event, setFieldValue) => {
    const selectedFile = event.target.files[0];
    setFieldValue('uploadedFile', selectedFile);

    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const validationSchema = Yup.object().shape({
    phone: Yup.string().required('Le numéro de téléphone est requis'),
    selectedDocument: Yup.string().required(
      'Veuillez sélectionner un document'
    ),
    uploadedFile: Yup.mixed().required('Veuillez télécharger un document'),
  });

  const resetForm = (formikBag) => {
    setStep(1);
    setPreview(null);
    setMessage({ type: '', text: '' });
    formikBag.resetForm();
  };

  return (
    <div className="p-8">
      {message.text && (
        <div
          className={`mb-4 rounded-lg p-4 ${
            message.type === 'success'
              ? 'bg-green-100 text-green-700'
              : 'bg-red-100 text-red-700'
          }`}
        >
          <div className="flex items-center">
            {message.type === 'success' ? (
              <svg
                className="mr-2 h-5 w-5 flex-shrink-0"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
            ) : (
              <svg
                className="mr-2 h-5 w-5 flex-shrink-0"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clipRule="evenodd"
                />
              </svg>
            )}
            <p>{message.text}</p>
          </div>
        </div>
      )}

      <div className="mb-8">
        <div className="flex items-center justify-between">
          {[1, 2, 3].map((stepNumber) => (
            <div key={stepNumber} className="flex flex-col items-center">
              <div
                className={`flex h-10 w-10 items-center justify-center rounded-full border-2 
                ${
                  step >= stepNumber
                    ? 'border-primary bg-primary text-white'
                    : 'border-gray-300'
                }`}
              >
                {stepNumber}
              </div>
              <span className="mt-2 text-sm text-gray-600">
                {getStepLabel(stepNumber)}
              </span>
            </div>
          ))}
        </div>
        <div className="mt-4 h-1 w-full bg-gray-200">
          <div
            className="h-1 bg-primary transition-all duration-500"
            style={{ width: `${((step - 1) / 2) * 100}%` }}
          />
        </div>
      </div>

      <Formik
        initialValues={{
          phone: '',
          selectedDocument: '',
          uploadedFile: null,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, formikBag) => {
          try {
            setIsSubmitting(true);
            const documentData = {};
            if (values.selectedDocument === 'cni') {
              documentData.cni = values.uploadedFile;
            } else if (values.selectedDocument === 'passport') {
              documentData.passport = values.uploadedFile;
            } else if (values.selectedDocument === 'residence_permit') {
              documentData.residence_permit = values.uploadedFile;
            } else if (values.selectedDocument === 'driving_license') {
              documentData.driving_license = values.uploadedFile;
            }

            const formData = new FormData();
            formData.append('phone', values.phone);
            formData.append('type', 'particular');
            Object.entries(documentData).forEach(([key, value]) => {
              formData.append(key, value);
            });
            await validateProfile(formData);

            setMessage({
              type: 'success',
              text: 'Documents soumis avec succès!',
            });

            setTimeout(() => {
              const modal = document.querySelector('#i-am-an-individual');
              const overlay = document.querySelector('.hs-overlay-backdrop');
              if (modal) {
                modal.classList.add('hidden');
              }
              if (overlay) {
                overlay.remove();
              }
              resetForm(formikBag);
              window.location.reload();
            }, 2000);
          } catch (error) {
            setMessage({
              type: 'error',
              text: error.response.data.message,
            });
          } finally {
            setIsSubmitting(false);
          }
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            {step === 1 && (
              <div className="space-y-4">
                <h3 className="text-xl font-semibold text-gray-800">
                  Entrez votre numéro de téléphone
                </h3>
                <div className="space-y-4">
                  <Field
                    type="tel"
                    name="phone"
                    placeholder="Numéro de téléphone"
                    className="w-full rounded-lg border border-gray-300 p-4"
                  />
                  {errors.phone && touched.phone && (
                    <div className="text-sm text-red-500">{errors.phone}</div>
                  )}
                  <button
                    type="button"
                    onClick={() => values.phone && setStep(2)}
                    disabled={!values.phone}
                    className={`w-full rounded-full py-2 text-white
                      ${
                        values.phone
                          ? 'bg-primary hover:bg-primary/90'
                          : 'cursor-not-allowed bg-gray-300'
                      }`}
                  >
                    Continuer
                  </button>
                </div>
              </div>
            )}

            {step === 2 && (
              <div className="space-y-6">
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold text-gray-800">
                    Choisissez et téléchargez votre document
                  </h3>
                  <div className="grid grid-cols-2 gap-4">
                    {documentTypes.map((doc) => (
                      <button
                        type="button"
                        key={doc.value}
                        onClick={() =>
                          setFieldValue('selectedDocument', doc.value)
                        }
                        className={`rounded-lg border-2 p-4 transition-colors hover:border-primary
                          ${
                            values.selectedDocument === doc.value
                              ? 'border-primary bg-primary/10'
                              : 'border-gray-200'
                          }`}
                      >
                        {doc.label}
                      </button>
                    ))}
                  </div>
                  {errors.selectedDocument && touched.selectedDocument && (
                    <div className="text-sm text-red-500">
                      {errors.selectedDocument}
                    </div>
                  )}
                </div>

                {values.selectedDocument && (
                  <div className="rounded-lg border-2 border-dashed border-gray-300 p-8 text-center">
                    <input
                      type="file"
                      id="fileUpload"
                      onChange={(e) => handleFileChange(e, setFieldValue)}
                      className="hidden"
                      accept="image/*,.pdf"
                    />
                    <label htmlFor="fileUpload" className="cursor-pointer">
                      {preview ? (
                        <img
                          src={preview}
                          alt="Aperçu"
                          className="mx-auto max-h-48"
                        />
                      ) : (
                        <div className="space-y-4">
                          <div className="mx-auto h-12 w-12 text-gray-400">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                              />
                            </svg>
                          </div>
                          <div className="text-gray-600">
                            Cliquez ou glissez votre fichier ici
                          </div>
                          <p className="mt-2 text-sm text-gray-500">
                            Important : Pour la pièce d&apos;identité, veuillez
                            uploader une seule image contenant à la fois le
                            recto et le verso du document. Les deux faces
                            doivent être clairement visibles sur la même image.
                          </p>
                        </div>
                      )}
                    </label>
                    {errors.uploadedFile && touched.uploadedFile && (
                      <div className="mt-2 text-sm text-red-500">
                        {errors.uploadedFile}
                      </div>
                    )}
                  </div>
                )}

                <div className="flex justify-between">
                  <button
                    type="button"
                    onClick={() => setStep(1)}
                    className="rounded-full border border-gray-300 px-6 py-2 hover:bg-gray-50"
                  >
                    Retour
                  </button>
                  <button
                    type="button"
                    onClick={() => values.uploadedFile && setStep(3)}
                    disabled={!values.uploadedFile}
                    className={`rounded-full px-6 py-2 text-white
                      ${
                        values.uploadedFile
                          ? 'bg-primary hover:bg-primary/90'
                          : 'cursor-not-allowed bg-gray-300'
                      }`}
                  >
                    Continuer
                  </button>
                </div>
              </div>
            )}

            {step === 3 && (
              <div className="space-y-6">
                <h3 className="text-xl font-semibold text-gray-800">
                  Vérifiez et validez
                </h3>

                <div className="space-y-4 rounded-lg border p-4">
                  <div className="flex items-center justify-between">
                    <span className="text-gray-600">Numéro de téléphone:</span>
                    <span className="font-medium">{values.phone}</span>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-gray-600">Document sélectionné:</span>
                    <span className="font-medium">
                      {
                        documentTypes.find(
                          (d) => d.value === values.selectedDocument
                        )?.label
                      }
                    </span>
                  </div>
                  {preview && (
                    <img
                      src={preview}
                      alt="Aperçu final"
                      className="mx-auto max-h-48"
                    />
                  )}
                </div>

                <div className="flex justify-between">
                  <button
                    type="button"
                    onClick={() => setStep(2)}
                    className="rounded-full border border-gray-300 px-6 py-2 hover:bg-gray-50"
                  >
                    Retour
                  </button>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="rounded-full bg-primary px-6 py-2 text-white hover:bg-primary/90 disabled:cursor-not-allowed disabled:opacity-50"
                  >
                    {isSubmitting ? (
                      <div className="flex items-center">
                        <svg
                          className="mr-2 h-4 w-4 animate-spin"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                            fill="none"
                          />
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          />
                        </svg>
                        Validation en cours...
                      </div>
                    ) : (
                      'Valider'
                    )}
                  </button>
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};
const DocumentUploadAssociation = ({ onSubmit }) => {
  const [step, setStep] = useState(1);
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [message, setMessage] = useState({ type: '', text: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const associationInfoFields = [
    { value: 'name', label: "Nom de l'association" },
    {
      value: 'siren_or_rna',
      label: 'SIREN ou RNA (numéro de déclaration de préfecture)',
    },
    { value: 'association_phone', label: 'Numéro de téléphone' },
    {
      value: 'association_address',
      label: 'Adresse',
      type: 'text',
      placeholder: "Adresse de l'association",
    },
  ];

  const documentTypes = [
    { value: 'statut_association', label: 'Statut à jour' },
    {
      value: 'receipt_statement',
      label: 'Récépissé déclaration en préfecture',
    },
    {
      value: 'release_news_official',
      label: 'Déclaration en journal officiel',
    },
  ];

  const getStepLabel = (stepNumber) => {
    switch (stepNumber) {
      case 1:
        return 'Informations';
      case 2:
        return 'Documents';
      case 3:
        return 'Validation';
      default:
        return '';
    }
  };

  // Validation schemas spécifiques à chaque étape
  const step1ValidationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Le nom de l'association est obligatoire")
      .min(2, 'Le nom doit faire au moins 2 caractères'),
    siren_or_rna: Yup.string().required('Le SIREN ou RNA est obligatoire'),
    association_phone: Yup.string().required(
      'Le numéro de téléphone est obligatoire'
    ),
    association_address: Yup.string().required("L'adresse est obligatoire"),
  });

  const step2ValidationSchema = Yup.object().shape({
    statut_association: Yup.mixed()
      .required('Le statut est obligatoire')
      .test(
        'fileSize',
        'Le fichier est trop volumineux (max 5MB)',
        (value) => !value || (value && value.size <= 5000000)
      ),
    receipt_statement: Yup.mixed()
      .required('Le récépissé est obligatoire')
      .test(
        'fileSize',
        'Le fichier est trop volumineux (max 5MB)',
        (value) => !value || (value && value.size <= 5000000)
      ),
    release_news_official: Yup.mixed()
      .required('La déclaration est obligatoire')
      .test(
        'fileSize',
        'Le fichier est trop volumineux (max 5MB)',
        (value) => !value || (value && value.size <= 5000000)
      ),
  });

  const getCurrentValidationSchema = () => {
    switch (step) {
      case 1:
        return step1ValidationSchema;
      case 2:
        return step2ValidationSchema;
      default:
        return Yup.object();
    }
  };

  const handleReset = (formikBag) => {
    setStep(1);
    setUploadedFiles({});
    setMessage({ type: '', text: '' });
    formikBag.resetForm();
  };

  const handleFileChange = (fieldName, file, setFieldValue) => {
    if (file) {
      if (file.size > 5000000) {
        setMessage({
          type: 'error',
          text: `Le fichier ${fieldName} est trop volumineux (max 5MB)`,
        });
        return;
      }
      setFieldValue(fieldName, file);
      setUploadedFiles((prev) => ({ ...prev, [fieldName]: file }));
    }
  };

  const closeModal = () => {
    const modal = document.querySelector('#i-am-an-association');
    if (modal) {
      modal.classList.add('hidden');
      document.body.classList.remove('overflow-hidden');
      const backdrop = document.querySelector('.hs-overlay-backdrop');
      if (backdrop) {
        backdrop.remove();
      }
    }
  };

  return (
    <div className="p-4">
      {message.text && (
        <div
          className={`mb-4 flex items-center rounded-lg p-4 ${
            message.type === 'error'
              ? 'bg-red-100 text-red-700'
              : 'bg-green-100 text-green-700'
          }`}
        >
          <svg
            className="mr-2 h-5 w-5 shrink-0"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            {message.type === 'error' ? (
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                clipRule="evenodd"
              />
            ) : (
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            )}
          </svg>
          <p>{message.text}</p>
        </div>
      )}
      <div className="mb-2 space-y-2">
        <div className="rounded-lg   text-sm text-gray-800">
          <p>
            Importez vos documents en cours de validité, en couleur et lisibles.
            Aucune partie ne doit être coupée.
          </p>
        </div>
        <div className="rounded-lg   text-sm text-green-800">
          <p>
            <span className="font-bold">Note :</span> Les fonds ne pourront être
            transférés que sur le compte bancaire appartenant à
            l&apos;association.
          </p>
        </div>
      </div>
      <div className="mb-8">
        <div className="flex items-center justify-between">
          {[1, 2, 3].map((stepNumber) => (
            <div key={stepNumber} className="flex flex-col items-center">
              <div
                className={`flex h-10 w-10 items-center justify-center rounded-full border-2 ${
                  step >= stepNumber
                    ? 'border-primary bg-primary text-white'
                    : 'border-gray-300'
                }`}
              >
                {stepNumber}
              </div>
              <span className="mt-2 text-sm font-medium text-gray-600">
                {getStepLabel(stepNumber)}
              </span>
            </div>
          ))}
        </div>
        <div className="mt-4 h-2 w-full rounded-full bg-gray-200">
          <div
            className="h-2 rounded-full bg-primary transition-all duration-500 ease-in-out"
            style={{ width: `${((step - 1) / 2) * 100}%` }}
          />
        </div>
      </div>
      <Formik
        initialValues={{
          name: '',
          siren_or_rna: '',
          association_phone: '',
          association_address: '',
          ...documentTypes.reduce(
            (acc, doc) => ({ ...acc, [doc.value]: null }),
            {}
          ),
        }}
        validationSchema={getCurrentValidationSchema()}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            setIsSubmitting(true);
            setMessage({ type: '', text: '' });

            const formData = new FormData();

            formData.append('name', values.name);
            formData.append('siren_or_rna', values.siren_or_rna);
            formData.append('association_phone', values.association_phone);
            formData.append('association_address', values.association_address);
            formData.append('type', 'association');

            Object.entries(uploadedFiles).forEach(([key, file]) => {
              if (file) {
                formData.append(key, file);
              }
            });

            await validateProfile(formData);

            setMessage({
              type: 'success',
              text: 'Documents soumis avec succès !',
            });

            setTimeout(() => {
              closeModal();
              handleReset({ resetForm: () => {} });
              window.location.reload();
            }, 1500);
          } catch (error) {
            setMessage({
              type: 'error',
              text:
                error.response.data.message ||
                'Une erreur est survenue lors de la soumission. Veuillez réessayer.',
            });
          } finally {
            setIsSubmitting(false);
            setSubmitting(false);
          }
        }}
      >
        {({
          values,
          errors: formErrors,
          touched,
          handleSubmit,
          setFieldValue,
          validateForm,
          setTouched,
        }) => (
          <Form onSubmit={handleSubmit} className="space-y-6">
            {step === 1 && (
              <div className="space-y-6">
                <h3 className="text-xl font-semibold text-gray-800">
                  Informations de l&apos;association
                </h3>
                <div className="space-y-4">
                  {associationInfoFields.map((field) => (
                    <div key={field.value}>
                      <label className="mb-1 block text-sm font-medium text-gray-700">
                        {field.label}
                      </label>
                      {field.value === 'association_address' ? (
                        <Field
                          name={field.value}
                          component={AddressAutocomplete}
                          setFieldValue={setFieldValue}
                        />
                      ) : (
                        <Field
                          name={field.value}
                          type={
                            field.value === 'association_phone' ? 'tel' : 'text'
                          }
                          className={`block w-full rounded-lg border p-3 shadow-sm transition duration-150 focus:border-primary focus:ring-2 focus:ring-primary/20 ${
                            formErrors[field.value] && touched[field.value]
                              ? 'border-red-500 bg-red-50'
                              : 'border-gray-300'
                          }`}
                          placeholder={`Entrez ${field.label.toLowerCase()}`}
                        />
                      )}
                      {formErrors[field.value] && touched[field.value] && (
                        <p className="mt-1 text-sm text-red-600">
                          {formErrors[field.value]}
                        </p>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {step === 2 && (
              <div>
                <h3 className="text-xl font-semibold text-gray-800">
                  Documents
                </h3>
                <div className="space-y-4">
                  {documentTypes.map((doc) => (
                    <div key={doc.value} className="space-y-2">
                      <label className="mb-1 block text-sm font-medium text-gray-700">
                        {doc.label}
                      </label>
                      <input
                        type="file"
                        accept={
                          doc.value === 'bank_statement'
                            ? '.pdf,.jpg,.jpeg,.png'
                            : '.pdf'
                        }
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (file) {
                            if (doc.value === 'bank_statement') {
                              // Accepte PDF, JPG, JPEG, PNG pour le relevé
                              if (
                                file.type === 'application/pdf' ||
                                file.type.startsWith('image/')
                              ) {
                                handleFileChange(
                                  doc.value,
                                  file,
                                  setFieldValue
                                );
                              }
                            } else if (file.type === 'application/pdf') {
                              // Uniquement PDF pour le RIB
                              handleFileChange(doc.value, file, setFieldValue);
                            }
                          }
                        }}
                        className="block w-full rounded-lg border p-3 shadow-sm transition duration-150 focus:border-primary focus:ring-2 focus:ring-primary/20"
                      />
                      {formErrors[doc.value] && touched[doc.value] && (
                        <p className="mt-1 text-sm text-red-600">
                          {formErrors[doc.value]}
                        </p>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {step === 3 && (
              <div className="space-y-4">
                <h3 className="text-xl font-semibold text-gray-800">
                  Validation
                </h3>
                <div className="space-y-3 rounded-lg border p-4">
                  <h4 className="font-medium">
                    Informations de l&apos;association
                  </h4>
                  {associationInfoFields.map((field) => (
                    <div key={field.value} className="flex justify-between">
                      <span className="text-gray-600">{field.label}:</span>
                      <span className="font-medium">{values[field.value]}</span>
                    </div>
                  ))}

                  <h4 className="pt-4 font-medium">Documents fournis</h4>
                  {documentTypes.map((doc) => (
                    <div key={doc.value} className="flex justify-between">
                      <span className="text-gray-600">{doc.label}:</span>
                      <span className="font-medium">
                        {uploadedFiles[doc.value]?.name || 'Non fourni'}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div className="flex justify-between">
              {step > 1 && (
                <button
                  type="button"
                  className="rounded-lg border border-gray-300 px-6 py-2 text-sm text-gray-800"
                  onClick={() => setStep(step - 1)}
                >
                  Précédent
                </button>
              )}

              {(() => {
                const handleStep1Click = async () => {
                  const validationErrors = await validateForm();
                  const currentFields = associationInfoFields.map(
                    (f) => f.value
                  );

                  const hasErrors = currentFields.some((field) => {
                    const error = validationErrors[field];
                    const value = values[field];
                    return error || !value;
                  });

                  if (!hasErrors) {
                    setStep(step + 1);
                    setMessage({ type: '', text: '' });
                  } else {
                    currentFields.forEach((field) => {
                      setTouched({ ...touched, [field]: true }, true);
                    });

                    setMessage({
                      type: 'error',
                      text: 'Veuillez remplir tous les champs obligatoires',
                    });
                  }
                };

                const handleStep2Click = async () => {
                  const validationErrors = await validateForm();
                  const hasErrors = Object.keys(validationErrors).length > 0;

                  if (!hasErrors) {
                    setStep(step + 1);
                    setMessage({ type: '', text: '' });
                  } else {
                    setMessage({
                      type: 'error',
                      text: 'Tous les champs sont obligatoires, veuillez vérifier vos informations',
                    });
                  }
                };

                const handleFinalSubmit = async (e) => {
                  // Renommé de handleSubmit à handleFinalSubmit
                  e.preventDefault();
                  if (step === 3) {
                    handleSubmit(); // Utilise le handleSubmit de Formik
                  }
                };

                if (step === 1) {
                  return (
                    <button
                      type="button"
                      onClick={handleStep1Click}
                      className="rounded-lg bg-primary px-6 py-2 text-sm text-white"
                      disabled={isSubmitting}
                    >
                      Suivant
                    </button>
                  );
                }

                if (step === 2) {
                  return (
                    <button
                      type="button"
                      onClick={handleStep2Click}
                      className="rounded-lg bg-primary px-6 py-2 text-sm text-white"
                      disabled={isSubmitting}
                    >
                      Suivant
                    </button>
                  );
                }

                return (
                  <button
                    type="button"
                    onClick={handleFinalSubmit} // Utilise le nouveau nom
                    disabled={isSubmitting}
                    className="rounded-full bg-primary px-6 py-2 text-white hover:bg-primary/90 disabled:cursor-not-allowed disabled:opacity-50"
                  >
                    {isSubmitting ? (
                      <div className="flex items-center">
                        <svg
                          className="mr-2 h-4 w-4 animate-spin"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                            fill="none"
                          />
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          />
                        </svg>
                        Validation en cours...
                      </div>
                    ) : (
                      'Soumettre'
                    )}
                  </button>
                );
              })()}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
const DeleteAccountDialog = () => {
  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteAccount = async () => {
    try {
      setIsDeleting(true);
      await deleteAccount();
      localStorage.removeItem('persist:root');
      navigate('/se-connecter');
    } catch (error) {
      console.error('Erreur lors de la suppression du compte:', error);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div
      id="delete-account-modal"
      className="hs-overlay fixed left-0 top-0 z-[60] hidden h-full w-full overflow-y-auto overflow-x-hidden"
    >
      <div className="m-3 mt-0 opacity-0 transition-all ease-out hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:w-full sm:max-w-lg">
        <div className="relative flex flex-col rounded-lg border bg-white shadow-sm">
          <div className="p-6">
            <div className="flex items-center justify-center">
              <div className="h-12 w-12 rounded-full bg-red-100 p-2">
                <svg
                  className="h-8 w-8 text-red-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
              </div>
            </div>
            <div className="mt-4 text-center">
              <h3 className="text-lg font-medium text-gray-900">
                Supprimer votre compte
              </h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  Êtes-vous sûr de vouloir supprimer votre compte ? Cette action
                  est irréversible et toutes vos données seront définitivement
                  supprimées.
                </p>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end gap-x-2 border-t p-4">
            <button
              type="button"
              className="rounded-md border px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              data-hs-overlay="#delete-account-modal"
            >
              Annuler
            </button>
            <button
              type="button"
              onClick={handleDeleteAccount}
              disabled={isDeleting}
              className="rounded-md bg-red-600 px-4 py-2 text-sm font-medium text-white hover:bg-red-700 disabled:opacity-50"
            >
              {isDeleting ? 'Suppression...' : 'Supprimer définitivement'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
const MyProfile = () => {
  const navigate = useNavigate();
  const publicformRef = useRef(null);
  const privateformRef = useRef(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingPassword, setLoadingPassword] = useState('');
  const [loadingProfile, setLoadingProfile] = useState('');
  const [loadingPrivate, setLoadingPrivate] = useState('');
  const [birthDate, setBirthDate] = useState();
  const [profile, setProfile] = useState();
  const [message, setMessage] = useState({
    public: [],
    private: [],
  });
  const [passwordMessage, setPasswordMessage] = useState([]);
  const [profileMessage, setProfileMessage] = useState([]);
  const [privateMessage, setPrivateMessage] = useState([]);
  const [userData, setUserData] = useState(null);
  const [isVerified, setIsVerified] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isRejected, setIsRejected] = useState(false);

  const redirect = () => {
    const location = window.location.href;
    setTimeout(() => {
      navigate(`/se-connecter?redirect=${location}`);
    }, 500);
  };

  const openCalendar = () => {
    setShowCalendar(true);
  };

  const closeCalendar = () => {
    setShowCalendar(false);
  };

  const calendarDateRef = useOutsideClick(closeCalendar);

  const logOut = () => {
    localStorage.removeItem('persist:root');
    redirect();
  };

  const getUser = async () => {
    getMyProfile()
      .then((res) => {
        setLoadingData(false);
        setUserData(res.data.user.original.data);
        const user = res.data.user.original.data;
        setIsVerified(user.verification_status === 'approved');
        setIsPending(user.verification_status === 'pending');
        setIsRejected(user.verification_status === 'rejected');
        if (user.avatar) {
          setProfile(user.avatar);
        }

        setTimeout(() => {
          publicformRef.current.setValues({
            public_name: user.public_name || '',
            biography: user.biography || '',
          });

          privateformRef.current.setValues({
            lastName: user.surname || '',
            firstName: user.name || '',
            birthDate: user.date_nais ? user.date_nais.split('T')[0] : '',
            email: user.email || '',
            address: user.adress || '',
            country: user.state || '',
            city: user.city || '',
            zipCode: user.postal || '',
          });
        }, 500);
      })
      .catch((err) => {
        setLoadingData(false);
        if (err?.response?.status === 401) {
          logOut();
        }
      });
  };

  const formikField = (field, value, setFieldValue) => {
    if (field.type === 'textarea') {
      return (
        <Field
          as="textarea"
          name={field.name}
          rows="6"
          placeholder={field.placeholder}
          disabled={field.disabled}
          className={`relative w-full rounded border border-gray-300 bg-white p-3.5
          ${field.disabled ? 'select-none bg-gray-200' : ''}`}
        />
      );
    }
    if (field.type === 'select') {
      if (field.hasValidation) {
        return (
          <Field
            as="select"
            name={field.name}
            className="w-full rounded border border-gray-300 bg-white p-3.5"
          >
            {field.listOptions.map((option) => {
              return <option value={option.value}>{option.name}</option>;
            })}
          </Field>
        );
      }
      return (
        <Field
          as="select"
          name={field.name}
          className={`relative w-full rounded border border-gray-300 bg-white p-3.5
          ${field.disabled ? 'select-none bg-gray-200' : ''}`}
          disabled={field.disabled}
        >
          {field.listOptions.map((option) => {
            return <option value={option.value}>{option.name}</option>;
          })}
        </Field>
      );
    }
    if (field.hasValidation) {
      if (field.type === 'textarea') {
        return (
          <Field
            name={field.name}
            as="textarea"
            placeholder={field.placeholder}
            className={`relative w-full rounded border border-gray-300 bg-white p-3.5
            ${field.disabled ? 'select-none bg-gray-200' : ''}`}
            disabled={field.disabled}
          />
        );
      }
      return (
        <Field
          name={field.name}
          type={field.type}
          placeholder={field.placeholder}
          className={`relative w-full rounded border border-gray-300 bg-white p-3.5
          ${field.disabled ? 'select-none bg-gray-200' : ''}`}
          validate={field.validate}
          disabled={field.disabled}
        />
      );
    }
    if (field.type === 'textarea') {
      return (
        <Field
          as="textarea"
          name={field.name}
          rows="6"
          placeholder={field.placeholder}
          disabled={field.disabled}
          className={`relative w-full rounded border border-gray-300 bg-white p-3.5
          ${field.disabled ? 'select-none bg-gray-200' : ''}`}
        />
      );
    }
    // Ajouter une condition pour le champ address
    if (field.name === 'address' || field.name === 'association_address') {
      return (
        <Field
          name={field.name}
          component={AddressAutocomplete}
          setFieldValue={setFieldValue}
        />
      );
    }

    return (
      <div
        className="relative"
        ref={() => field.name === 'birthDate' && calendarDateRef}
      >
        <Field
          name={field.name}
          type={field.type}
          placeholder={field.placeholder}
          disabled={field.disabled}
          className={`relative w-full rounded border border-gray-300 bg-white p-3.5
          ${field.disabled ? 'select-none bg-gray-200' : ''}`}
          onClick={() => field.name === 'birthDate' && openCalendar()}
        />

        {showCalendar && field.name === 'birthDate' && (
          <Calendar
            className="rounded shadow-md shadow-slate-200 sm:left-0 sm:top-full sm:z-10 lg:absolute"
            onShownDateChange={(date) => {
              const currentDate = new Date(date);
              const currentBirthDate = new Date(date);

              if (birthDate) {
                currentDate.setDate(birthDate.getDate() + 1); // set birthDate to current date
                currentBirthDate.setDate(birthDate.getDate());
              } else {
                currentDate.setDate(currentDate.getDate() + 1); // Ajoute un jour à la date actuelle
              }

              privateformRef.current.setFieldValue(
                'birthDate',
                currentDate.toISOString().split('T')[0]
              );
              setShowCalendar(false);
            }}
            onChange={(date) => {
              const currentDate = new Date(date);
              // add 1 day to current date
              currentDate.setDate(currentDate.getDate() + 1);
              privateformRef.current.setFieldValue(
                'birthDate',
                currentDate.toISOString().split('T')[0]
              );
              setShowCalendar(false);
            }}
            locale={fr}
            color="#FD6E56B7"
          />
        )}
      </div>
    );
  };

  const INFORMATIONS = {
    public: {
      profile: [
        {
          name: 'public_name',
          type: 'text',
          title: 'Nom public',
          placeholder: 'Kuadra',
          hasValidation: false,
          semiWidth: false,
        },
        {
          name: 'biography',
          type: 'textarea',
          title: 'Bio',
          placeholder: 'Quelques mots sur vous...',
          hasValidation: false,
          semiWidth: false,
        },
      ],
      updatePassword: [
        {
          name: 'oldPassword',
          type: 'password',
          title: 'Ancien mot de passe',
          placeholder: '',
          hasValidation: false,
          semiWidth: false,
        },
        {
          name: 'newPassword',
          type: 'password',
          title: 'Nouveau mot de passe',
          placeholder: '',
          hasValidation: true,
          semiWidth: false,
        },
        {
          name: 'confirmPassword',
          type: 'password',
          title: 'Confirmez votre mot de passe',
          placeholder: '',
          hasValidation: true,
          semiWidth: false,
        },
      ],
    },
    private: [
      {
        name: 'lastName',
        type: 'text',
        title: 'Nom',
        placeholder: 'Angel',
        hasValidation: true,
        semiWidth: true,
        disabled: true,
      },
      {
        name: 'firstName',
        type: 'text',
        title: 'Prenom',
        placeholder: 'Melissa',
        hasValidation: false,
        semiWidth: true,
        disabled: true,
      },
      {
        name: 'birthDate',
        type: 'date',
        title: 'Date de naissance',
        hasValidation: false,
        semiWidth: false,
        disabled: false,
      },
      {
        name: 'email',
        type: 'email',
        title: 'Adresse email',
        placeholder: '',
        hasValidation: false,
        semiWidth: false,
        disabled: true,
      },
      {
        name: 'address',
        type: 'text',
        title: 'Numéro et nom de la voie',
        placeholder: '',
        hasValidation: false,
        semiWidth: false,
        disabled: false,
      },
      {
        name: 'country',
        type: 'select',
        title: 'Pays',
        listOptions: countries.fr,
        placeholder: '',
        hasValidation: false,
        semiWidth: false,
        disabled: false,
      },
      {
        name: 'city',
        type: 'text',
        title: 'Ville',
        placeholder: '',
        hasValidation: false,
        semiWidth: true,
        disabled: false,
      },
      {
        name: 'zipCode',
        type: 'text',
        title: 'Code postal',
        placeholder: '10178',
        hasValidation: false,
        semiWidth: true,
        disabled: false,
      },
    ],
  };

  const updatePassword = (oldPassword, newPassword, resetForm) => {
    setLoadingPassword(true);
    updateUser({
      oldpassword: oldPassword,
      password: newPassword,
    })
      .then(() => {
        setPasswordMessage([
          true,
          'Votre mot de passe a bien été réinitialisé',
        ]);
        setLoadingPassword(false);
        resetForm();
      })
      .catch((err) => {
        if (err.response.status) {
          setPasswordMessage([false, 'Ancien mot de passe incorrect']);
        } else {
          setPasswordMessage([
            false,
            "Une erreur s'est produite. Veuillez réessayer.",
          ]);
        }
        setLoadingPassword(false);
      });
  };

  const updateProfile = (values) => {
    setLoadingProfile(true);
    const formData = new FormData();

    // Ajouter tous les champs au FormData, sauf l'avatar
    Object.keys(values).forEach((key) => {
      if (values[key] !== null && values[key] !== undefined) {
        formData.append(key, values[key]);
      }
    });

    updateUser(formData)
      .then(() => {
        setProfileMessage([true, 'Votre profil a bien été mis à jour']);
        setLoadingProfile(false);
      })
      .catch((error) => {
        console.error("Erreur d'envoi:", error);
        setProfileMessage([
          false,
          "Une erreur s'est produite. Veuillez réessayer.",
        ]);
        setLoadingProfile(false);
      });
  };

  const updatePersonalData = (data) => {
    setLoadingPrivate(true);
    updateUser(data)
      .then(() => {
        setPrivateMessage([
          true,
          'Vos données personnelles ont bien été mis à jour',
        ]);
        setLoadingPrivate(false);
      })
      .catch(() => {
        setPrivateMessage([
          false,
          "Une erreur s'est produite. Veuillez réessayer.",
        ]);
        setLoadingPrivate(false);
      });
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <div className="container mx-auto max-w-7xl md:px-8 lg:px-24">
      <Helmet title="Mon profil - KOUDPOUSS" />
      <Header />
      <section className="bg-white px-6 pb-20 pt-6">
        {/* Menus */}
        <SubHeader currentPage={1} />
        {loadingData && (
          <div className="relative text-center">
            <div
              className={`mx-auto text-center transition-colors delay-300
                ${loadingData ? 'text-primary' : 'text-transparent'}`}
            >
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-live="polite"
                aria-busy="true"
                aria-labelledby="title-08a desc-08a"
                className="mx-auto h-16 w-16 md:h-24 md:w-24"
              >
                <title id="title-08a">loading</title>
                <path
                  d="M7 8H3V16H7V8Z"
                  className="animate animate-bounce fill-current "
                />
                <path
                  d="M14 8H10V16H14V8Z"
                  className="animate animate-bounce fill-current [animation-delay:.2s]"
                />
                <path
                  d="M21 8H17V16H21V8Z"
                  className="animate animate-bounce fill-current [animation-delay:.4s]"
                />
              </svg>
            </div>
          </div>
        )}
        {/* Forms */}
        {!loadingData && (
          <section className="w-full gap-y-4 lg:grid lg:grid-cols-2 lg:gap-5">
            {/* Public information */}
            <div className="relative h-full w-full">
              <Formik
                innerRef={publicformRef}
                initialValues={{
                  public_name: '',
                  biography: '',
                  avatar: null,
                }}
                validationSchema={PublicInformationSchema}
                onSubmit={(values) => {
                  console.log(values);
                  setProfileMessage([]);
                  updateProfile(values);
                }}
              >
                {({ errors, touched, values, setFieldValue, getFieldMeta }) => (
                  <Form className="h-full">
                    <div className="min-h-full border border-gray-200 bg-white p-10 shadow-md">
                      <h2 className="mb-6 text-center text-2xl text-primary">
                        Profil
                      </h2>
                      <figure className="mb-4 flex items-center">
                        {userData?.surname && userData?.name && !profile && (
                          <span className="inline-flex h-[3.875rem] w-[3.875rem] items-center justify-center rounded-full bg-gray-600">
                            <span className="text-lg font-medium leading-none text-white">
                              {userData.surname.charAt(0)}{' '}
                              {userData.name.charAt(0)}
                            </span>
                          </span>
                        )}

                        {profile && (
                          <span
                            className="mr-3 h-[3.875rem] w-[3.875rem] rounded-full bg-slate-200/50 bg-cover bg-no-repeat"
                            style={{
                              backgroundImage: `url(${profile})`,
                            }}
                          />
                        )}

                        <label
                          htmlFor="avatar"
                          className="my-6 block w-20 text-center"
                        >
                          <span className="ml-4 cursor-pointer rounded-md bg-gray-100 px-4 py-2 text-sm text-gray-500 hover:bg-gray-200">
                            Changer
                          </span>
                          <input
                            name="avatar"
                            type="file"
                            id="avatar"
                            accept="image/*"
                            onChange={(event) => {
                              const file = event.currentTarget.files[0];
                              if (file) {
                                setProfile(URL.createObjectURL(file));
                                setFieldValue('avatar', file);
                              }
                            }}
                            className="hidden"
                          />
                        </label>
                      </figure>

                      <div className="profile flex flex-wrap justify-between gap-y-4">
                        {INFORMATIONS.public.profile.map((field) => {
                          return (
                            <div
                              key={`private_${field.name}`}
                              className={`flex flex-col px-2 ${
                                field.semiWidth ? 'sm:w-1/2' : 'w-full '
                              }`}
                            >
                              <div className="flex flex-col items-start justify-start space-y-1">
                                <h3 className="text-xl leading-normal text-gray-900">
                                  {[field.title]}
                                </h3>
                              </div>
                              <div className="relative">
                                {formikField(field, values[field.name])}
                                {field.name === 'biography' && (
                                  <div className="mt-1 text-right text-sm text-gray-500">
                                    {values.biography.length}/500 caractères
                                  </div>
                                )}
                                {errors[field.name] && touched[field.name] ? (
                                  <div className="text-red-400">
                                    {errors[field.name]}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      {profileMessage.length > 0 && (
                        <div
                          className={`mb-4 text-center text-sm ${
                            profileMessage[0] ? 'text-primary' : 'text-red-500'
                          }`}
                        >
                          {profileMessage[1]}
                        </div>
                      )}
                      <div className="flex justify-center">
                        <button
                          type="submit"
                          className={`button-glassmorphism relative mt-10 w-fit items-start justify-center rounded-full px-9 py-4 text-base font-bold leading-tight text-white
                            ${
                              !loadingProfile && 'duration-300 hover:scale-105'
                            }`}
                        >
                          {loadingProfile
                            ? 'En cours'
                            : 'Mettre à jour mon profil'}
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>

            {/* Private information */}
            <div className="relative h-full w-full">
              <Formik
                innerRef={privateformRef}
                initialValues={{
                  lastName: '',
                  firstName: '',
                  birthDate: '',
                  email: '',
                  address: '',
                  country: '',
                  city: '',
                  zipCode: '',
                }}
                validationSchema={PrivateInformationSchema}
                onSubmit={(values) => {
                  setPrivateMessage([]);
                  if (!loadingPrivate) {
                    const formData = new FormData();
                    Object.keys(values).forEach((key) => {
                      formData.append(key, values[key]);
                    });
                    updatePersonalData({
                      surname: values.lastName,
                      name: values.firstName,
                      email: values.email,
                      date_nais: values.birthDate,
                      city: values.city,
                      adress: values.address,
                      postal: values.zipCode,
                      state: values.country,
                    });
                  }
                }}
              >
                {({ errors, touched }) => (
                  <Form className="h-full">
                    <div className="min-h-full border border-gray-200 bg-white p-10 shadow-md">
                      <h2 className="mb-6 text-center text-2xl text-primary">
                        Informations personnelles
                      </h2>

                      <p className="mb-4 text-left text-sm text-gray-500">
                        Si vous souhaitez changer votre nom, prénom ou même
                        votre adresse email, merci de nous contacter à
                        l&apos;adresse suivante :{' '}
                        <a
                          href="mailto:pole-securite@koud-pouss.com
                          "
                          className="text-primary"
                        >
                          pole-securite@koud-pouss.com
                        </a>
                        <br />
                        <br />
                        Pourquoi je ne peux pas le faire moi-même ? Pour en
                        savoir plus,{' '}
                        <a
                          href="/faq"
                          target="_blank"
                          rel="noreferrer"
                          className="text-primary"
                        >
                          cliquez ici
                        </a>
                      </p>
                      <div className="flex flex-wrap justify-between gap-y-4">
                        {INFORMATIONS.private.map((field) => {
                          return (
                            <div
                              key={`private${field.name}`}
                              className={`flex flex-col px-2 ${
                                field.semiWidth ? 'sm:w-1/2' : 'w-full '
                              }`}
                            >
                              <div className="flex flex-col items-start justify-start space-y-1">
                                <h3 className="text-xl leading-normal text-gray-900">
                                  {[field.title]}
                                </h3>
                              </div>
                              <div className="">
                                {formikField(field)}
                                {errors[field.name] && touched[field.name] ? (
                                  <div className="text-red-400">
                                    {errors[field.name]}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      {privateMessage.length > 0 && (
                        <div
                          className={`my-4 text-center text-sm ${
                            privateMessage[0] ? 'text-primary' : 'text-red-500'
                          }`}
                        >
                          {privateMessage[1]}
                        </div>
                      )}
                      <div className="flex justify-center">
                        <button
                          type="submit"
                          className={`button-glassmorphism relative mt-10 w-fit items-start justify-center rounded-full px-9 py-4 text-base font-bold leading-tight text-white
                            ${
                              !loadingPrivate && 'duration-300 hover:scale-105'
                            }`}
                        >
                          {loadingPrivate
                            ? 'En cours'
                            : 'Mettre à jour les données personnelles'}
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>

            {/* Update Password */}
            <div className="relative h-full w-full">
              <Formik
                initialValues={{
                  oldPassword: '',
                  newPassword: '',
                  confirmPassword: '',
                }}
                validationSchema={UpdatePasswordSchema}
                onSubmit={(values, { resetForm }) => {
                  setPasswordMessage([]);
                  if (!loadingPassword) {
                    updatePassword(
                      values.oldPassword,
                      values.newPassword,
                      resetForm
                    );
                  }
                }}
              >
                {({ errors, touched }) => (
                  <Form className="h-full">
                    <div className="min-h-full border border-gray-200 bg-white p-10 shadow-md">
                      <h2 className="mb-6 text-center text-2xl text-primary">
                        Modifier mot de passe
                      </h2>
                      <div className="flex flex-wrap justify-between gap-y-4">
                        {INFORMATIONS.public.updatePassword.map((field) => {
                          return (
                            <div
                              key={`private_${field.name}`}
                              className={`flex flex-col px-2 ${
                                field.semiWidth ? 'sm:w-1/2' : 'w-full '
                              }`}
                            >
                              <div className="flex flex-col items-start justify-start space-y-1">
                                <h3 className="text-xl leading-normal text-gray-900">
                                  {[field.title]}
                                </h3>
                              </div>
                              <div className="">
                                {formikField(field)}
                                {errors[field.name] && touched[field.name] ? (
                                  <div className="text-red-400">
                                    {errors[field.name]}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      {passwordMessage.length > 0 && (
                        <div
                          className={`mb-4 text-center text-sm ${
                            passwordMessage[0] ? 'text-primary' : 'text-red-500'
                          }`}
                        >
                          {passwordMessage[1]}
                        </div>
                      )}
                      <div className="flex justify-center">
                        <button
                          type="submit"
                          className={`button-glassmorphism relative mt-10 w-fit items-start justify-center rounded-full px-9 py-4 text-base font-bold leading-tight text-white
                            ${
                              !loadingPassword && 'duration-300 hover:scale-105'
                            }`}
                        >
                          {loadingPassword ? 'En cours' : 'Valider'}
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>

            {/* Call to actions buttons */}
            <div className="relative h-full w-full">
              <div className="h-full">
                <div className="min-h-full border border-gray-200 bg-white p-10 shadow-md">
                  <h2 className="mb-6 text-center text-2xl text-primary">
                    Certifier son compte
                  </h2>
                  {message.private.length > 0 && (
                    <div
                      className={`text-center text-sm ${
                        message.public[0] ? 'text-green-500' : 'text-secondary'
                      }`}
                    >
                      {message.public[1]}
                    </div>
                  )}
                  <ul className="mx-auto mt-4 max-w-sm space-y-10 sm:px-10">
                    <li>
                      <button
                        disabled={isVerified || isPending}
                        type="submit"
                        data-hs-overlay="#i-am-an-individual"
                        className={`button-glassmorphism relative mx-auto w-full items-start justify-center rounded-full px-9 py-4 text-base font-bold leading-tight text-white
                            `}
                      >
                        Je suis un particulier
                      </button>
                    </li>
                    <li>
                      <button
                        type="submit"
                        disabled={isVerified || isPending}
                        data-hs-overlay="#i-am-an-association"
                        className={`button-glassmorphism relative mx-auto w-full items-start justify-center rounded-full px-9 py-4 text-base font-bold leading-tight text-white
                            ${!loading && 'duration-300 hover:scale-105'}`}
                      >
                        Je suis une association
                      </button>
                    </li>
                  </ul>
                  {(() => {
                    if (isVerified) {
                      return (
                        <p className="mt-10 text-center text-green-600">
                          Vos documents ont été certifiés avec succès. Vous
                          pouvez maintenant utiliser toutes les fonctionnalités
                          de votre compte.
                        </p>
                      );
                    }
                    if (isPending) {
                      return (
                        <p className="mt-10 text-center text-yellow-600">
                          Vos documents sont en cours de vérification.
                        </p>
                      );
                    }
                    if (isRejected) {
                      return (
                        <p className="mt-10 text-center text-red-600">
                          Vos documents ont été rejetés. Veuillez consulter
                          votre adresse email pour connaître le motif du rejet
                          et soumettre à nouveau vos documents.
                        </p>
                      );
                    }
                    return (
                      <p className="mt-10 text-center text-xs">
                        <span className="text-red-600">Important</span> : Pour
                        finaliser votre collecte, vous devez certifier votre
                        compte dans un délai de 90 jours à compter de sa
                        création. Sans certification, les dons reçus seront
                        automatiquement remboursés aux donateurs.
                      </p>
                    );
                  })()}
                </div>
              </div>
            </div>

            <div className="col-span-2 border border-gray-200 bg-white p-4 shadow-md sm:p-6 md:p-8 lg:p-10">
              <BankAccountUpload />
            </div>
          </section>
        )}
        {!loadingData && (
          <div className="mt-4 w-full">
            <button
              type="button"
              data-hs-overlay="#delete-account-modal"
              className="ml-auto flex items-center leading-none text-red-600 hover:text-red-700"
            >
              <IconTrash color="currentColor" />
              <span className="ml-3 mt-1">Supprimer mon compte</span>
            </button>
          </div>
        )}
        {/* Verify */}
        {/* I am an individual */}
        <div
          id="i-am-an-individual"
          className="hs-overlay fixed left-0 top-0 z-[60] hidden h-full w-full overflow-y-auto overflow-x-hidden"
        >
          <div className="m-3 opacity-0 transition-all hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:w-full sm:max-w-lg">
            <div className="flex flex-col border bg-white shadow-sm">
              <div className="flex items-center justify-end border-b px-4 py-3">
                <button
                  type="button"
                  className="hs-dropdown-toggle inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-md text-sm text-primary transition-all hover:text-primary focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2"
                  data-hs-overlay="#i-am-an-individual"
                >
                  <span className="sr-only">Fermer</span>
                  <svg
                    className="h-3.5 w-3.5"
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              </div>
              <DocumentUpload />
            </div>
          </div>
        </div>
        {/* I am an association */}
        <div
          id="i-am-an-association"
          className="hs-overlay fixed left-0 top-0 z-[60] hidden h-full w-full overflow-y-auto overflow-x-hidden"
        >
          <div className="m-3 opacity-0 transition-all hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:w-full sm:max-w-lg">
            <div className="flex flex-col border bg-white shadow-sm">
              <div className="flex items-center justify-end border-b px-4 py-3">
                <button
                  type="button"
                  className="hs-dropdown-toggle inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-md text-sm text-primary transition-all hover:text-primary focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2"
                  data-hs-overlay="#i-am-an-association"
                >
                  <span className="sr-only">Fermer</span>
                  <svg
                    className="h-3.5 w-3.5"
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              </div>
              <DocumentUploadAssociation />
            </div>
          </div>
        </div>
      </section>
      <DeleteAccountDialog />
    </div>
  );
};

export default MyProfile;
