/* eslint-disable */
import { getSupporters } from 'api/user';
import Header from 'components/Header';
import IconSearch from 'components/icons/IconSearch';
import SubHeader from 'components/SubHeader';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';

const MySupportersSchema = Yup.object().shape({
  search: Yup.string(),
});
const MySupporters = () => {
  const [loading, setLoading] = useState(false);
  const [supportersList, setSupportersList] = useState([]);
  const [supportersError, setSupportersError] = useState(null);
  
  // Pagination
  const [supportersPagination, setSupportersPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    perPage: 10,
    total: 0
  });

  const fetchSupporters = async (page = 1) => {
    setLoading(true);
    try {
      const response = await getSupporters(page); // Assurez-vous que votre API prend en charge la pagination
      setSupportersList(response.data.data || []);
      setSupportersPagination({
        currentPage: response.data.meta.pagination.current_page,
        totalPages: response.data.meta.pagination.total_pages,
        perPage: response.data.meta.pagination.per_page,
        total: response.data.meta.pagination.total
      });
    } catch (error) {
      console.error('Erreur lors du chargement des supporters:', error);
      setSupportersError("Une erreur est survenue lors du chargement des supporters");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSupporters(); // Appeler la fonction lors du chargement du composant
  }, []);

  const handlePageChange = (newPage) => {
    if (newPage !== supportersPagination.currentPage) {
      fetchSupporters(newPage); // Passer la nouvelle page à la fonction
    }
  };


  return (
    <div className="container mx-auto max-w-7xl md:px-8 lg:px-24">
      <Helmet title="Mes supporters - KOUDPOUSS" />
      <Header />
      <section className="bg-white px-6 pb-20 pt-6">
        {/* Menus */}
        <SubHeader currentPage={3} />
        <section className="sm:px-20">
          <h1 className="mb-12 mt-6 px-10 text-center text-2xl text-primary">
            Mes supporters
          </h1>
          {/* Search Form */}
          <Formik
            initialValues={{ search: '' }}
            validationSchema={MySupportersSchema}
            // eslint-disable-next-line no-unused-vars, unused-imports/no-unused-vars
            onSubmit={(values) => {
              if (!loading) {
                setLoading(false);
              }
            }}
          >
            <Form className="relative flex items-center justify-end gap-4 sm:mr-10 sm:pr-10 lg:mb-0">
              <div className="flex w-full max-w-xs rounded-full border border-slate-300  bg-gray-300/20 p-3.5">
                <Field
                  name="search"
                  type="text"
                  placeholder="Soutenons l’asso…"
                  className="grow bg-transparent"
                />
                <figure className="text-primary">
                  <IconSearch />
                </figure>
              </div>
              {/* <span className="text-primary">
                {loading ? 'En cours' : 'Filtrer'}
              </span> */}
            </Form>
          </Formik>
          {/* Results */}
          {supportersError && (
            <div className="text-red-500">{supportersError}</div>
          )}
          {loading ? (
            <div>Chargement...</div>
          ) : (
            <div className="mt-10 border border-gray-200 bg-white px-6 pb-10 shadow-md sm:px-10">
              <ul className="list-none space-y-6">
                {supportersList.map((supporter) => (
                  <li
                    key={`private_${supporter.supporter_id}`}
                    className="mx-auto mt-10 max-w-lg bg-white"
                  >
                    <ul className="mx-auto border-t border-gray-300">
                      <li className="flex flex-col border-x border-b border-gray-300 p-3.5 font-bold sm:flex-row">
                        <span className="w-44 shrink-0 text-primary">Nom</span>
                        <span className="grow">{supporter.last_name}</span>
                      </li>
                      <li className="flex flex-col border-x border-b border-gray-300 p-3.5 font-bold sm:flex-row">
                        <span className="w-44 shrink-0 text-primary">Prénom</span>
                        <span className="grow">{supporter.first_name}</span>
                      </li>
                      <li className="flex flex-col border-x border-b border-gray-300 p-3.5 font-bold sm:flex-row">
                        <span className="w-44 shrink-0 text-primary">Email</span>
                        <span className="grow">{supporter.email}</span>
                      </li>
                      <li className="flex flex-col border-x border-b border-gray-300 p-3.5 font-bold sm:flex-row">
                        <span className="w-44 shrink-0 text-primary">Montant</span>
                        <span className="grow">{supporter.amount} €</span>
                      </li>
                      <li className="flex flex-col border-x border-b border-gray-300 p-3.5 font-bold sm:flex-row">
                        <span className="w-44 shrink-0 text-primary">Méthode de paiement</span>
                        <span className="grow">{supporter.payment_method}</span>
                      </li>
                      {/* <li className="flex flex-col border-x border-b border-gray-300 p-3.5 font-bold sm:flex-row">
                        <span className="w-44 shrink-0 text-primary">Statut</span>
                        <span className="grow">{supporter.status}</span>
                      </li> */}
                      <li className="flex flex-col border-x border-b border-gray-300 p-3.5 font-bold sm:flex-row">
                        <span className="w-44 shrink-0 text-primary">Date de paiement</span>
                        <span className="grow">{new Date(supporter.created_at).toLocaleDateString('fr-FR', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        })}</span>
                      </li>
                      {/* <li className="flex flex-col border-x border-b border-gray-300 p-3.5 font-bold sm:flex-row">
                        <span className="w-44 shrink-0 text-primary">Date de mise à jour</span>
                        <span className="grow">{new Date(supporter.updated_at).toLocaleDateString('fr-FR', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        })}</span>
                      </li> */}
                      <li className="flex flex-col border-x border-b border-gray-300 p-3.5 font-bold sm:flex-row">
                        <span className="w-44 shrink-0 text-primary">Commentaire</span>
                        <span className="grow">{supporter.commentary ?? 'Aucun commentaire'}</span>
                      </li>
                    </ul>
                  </li>
                ))}
              </ul>
              {/* Pagination */}
              <Pagination
                currentPage={supportersPagination.currentPage}
                totalPages={supportersPagination.totalPages}
                onPageChange={handlePageChange}
                isLoading={loading}
                totalItems={supportersPagination.total}
                itemsPerPage={supportersPagination.perPage}
              />
            </div>
          )}
        </section>
      </section>
    </div>
  );
};

// Composant de pagination
const Pagination = ({ currentPage, totalPages, onPageChange, isLoading, totalItems, itemsPerPage }) => {
  const getPageRange = () => {
    const range = [];
    const delta = 2; // Nombre de pages à afficher de chaque côté

    for (let i = Math.max(2, currentPage - delta); i <= Math.min(totalPages - 1, currentPage + delta); i++) {
      range.push(i);
    }

    if (currentPage - delta > 2) {
      range.unshift('...');
    }
    if (currentPage + delta < totalPages - 1) {
      range.push('...');
    }

    if (totalPages > 1) {
      range.unshift(1);
      range.push(totalPages);
    }

    return range;
  };

  const pageRange = getPageRange();
  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(currentPage * itemsPerPage, totalItems);

  return (
    <div className="mt-6 flex flex-col items-center gap-4 sm:flex-row sm:justify-between">
      <div className="text-sm text-gray-700">
        Affichage de <span className="font-medium">{startItem}</span> - <span className="font-medium">{endItem}</span> sur <span className="font-medium">{totalItems}</span> résultats
      </div>
      <nav className="flex items-center gap-2" aria-label="Pagination">
        <button
          type="button"
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1 || isLoading}
          className={`inline-flex items-center px-4 py-2 text-sm font-medium ${currentPage === 1 ? 'bg-gray-100 text-gray-400 cursor-not-allowed' : 'bg-white text-gray-700 hover:bg-gray-50 hover:text-gray-900'} border border-gray-300 rounded-md`}
        >
          Précédent
        </button>
        <div className="hidden sm:flex gap-2">
          {pageRange.map((page, index) => (
            page === '...' ? (
              <span key={`ellipsis-${index}`} className="px-4 py-2">...</span>
            ) : (
              <button
                key={page}
                type="button"
                onClick={() => onPageChange(page)}
                disabled={page === currentPage || isLoading}
                className={`inline-flex items-center px-4 py-2 text-sm font-medium ${page === currentPage ? 'bg-primary text-white hover:bg-primary/90' : 'bg-white text-gray-700 hover:bg-gray-50 hover:text-gray-900'} border border-gray-300 rounded-md`}
              >
                {page}
              </button>
            )
          ))}
        </div>
        <button
          type="button"
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages || isLoading}
          className={`inline-flex items-center px-4 py-2 text-sm font-medium ${currentPage === totalPages ? 'bg-gray-100 text-gray-400 cursor-not-allowed' : 'bg-white text-gray-700 hover:bg-gray-50 hover:text-gray-900'} border border-gray-300 rounded-md`}
        >
          Suivant
        </button>
      </nav>
    </div>
  );
};

// Validation des props
Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  totalItems: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
};

export default MySupporters;
