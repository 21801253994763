import Editcollection from 'pages/modifier-ma-collecte';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Layout from './layout';
import Index from './pages';
import NoMatch from './pages/404';
import About from './pages/a-propos-de-nous';
import ResetPassword from './pages/changer-mot-de-passe';
import NewCollection from './pages/choisir-un-titre';
import Ranking from './pages/classement';
import Collection from './pages/collecte';
import HowItWork from './pages/comment-ca-marche';
import HowToStart from './pages/comment-debuter';
import Confirm from './pages/confirmer-le-compte';
import Contact from './pages/contactez-nous';
import Register from './pages/creer-un-compte';
import Faq from './pages/faq';
import TransferHistory from './pages/historique-de-mes-virements';
import StartADonation from './pages/lancer-une-collecte';
import MyCollections from './pages/mes-collectes';
import MySupporters from './pages/mes-supporters';
import MyProfile from './pages/mon-profil';
import MakeDonation from './pages/offrir-un-don';
import PrivacyPolicy from './pages/politique-de-confidentialite';
import Profile from './pages/profil';
import Reinitialiser from './pages/reinitialiser';
import Login from './pages/se-connecter';
import PopularCollection from './pages/ta-collecte-populaire';
import Dashboard from './pages/tableau-de-bord';
import Pricing from './pages/tarifs';

const App = () => {
  return (
    <BrowserRouter>
      <Routes onUpdate={() => window.scrollTo(0, 0)}>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Index />} />
          <Route path="/offrir-un-don/:slug" element={<MakeDonation />} />
          <Route path="/a-propos-de-nous" element={<About />} />
          <Route path="/contactez-nous" element={<Contact />} />
          <Route path="/classement" element={<Ranking />} />
          <Route path="/mes-supporters" element={<MySupporters />} />
          <Route
            path="/historique-de-mes-virements"
            element={<TransferHistory />}
          />
          <Route
            path="/ma-collecte-populaire"
            element={<PopularCollection />}
          />
          <Route path="/comment-ca-marche" element={<HowItWork />} />
          <Route path="/collecte/:slug" element={<Collection />} />
          <Route path="/lancer-une-collecte" element={<StartADonation />} />
          <Route path="/mes-collectes" element={<MyCollections />} />
          <Route
            path="/lancer-une-collecte/nom/:name"
            element={<StartADonation />}
          />
          <Route path="/profil" element={<Profile />} />
          <Route path="/comment-debuter" element={<HowToStart />} />
          <Route path="/tarifs" element={<Pricing />} />
          <Route path="/choisir-un-titre" element={<NewCollection />} />
          <Route
            path="/choisir-un-titre/categorie/:category/nom/:name"
            element={<NewCollection />}
          />
          <Route
            path="/choisir-un-titre/categorie/:category"
            element={<NewCollection />}
          />
          <Route
            path="/modifier-ma-collecte/:slug"
            element={<Editcollection />}
          />
          <Route path="/faq" element={<Faq />} />
          <Route path="/tableau-de-bord" element={<Dashboard />} />
          <Route path="/mon-profil" element={<MyProfile />} />
          <Route path="/creer-un-compte" element={<Register />} />
          <Route path="/se-connecter" element={<Login />} />
          <Route path="/confirmer-le-compte/:token" element={<Confirm />} />
          <Route
            path="/changer-mot-de-passe/:token"
            element={<ResetPassword />}
          />
          <Route path="/reinitialiser" element={<Reinitialiser />} />
          <Route
            path="/politique-de-confidentialite"
            element={<PrivacyPolicy />}
          />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
