/* eslint-disable simple-import-sort/imports */

import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { Player } from 'video-react';
import Header from 'components/Header';
import IconCheckCircle from 'components/icons/IconCheckCircle';
import SubHeader from 'components/SubHeader';
import { getMyProfile } from 'api/user';

const Profile = () => {
  const [user, setUser] = useState({});

  useEffect(() => {
    const fetchProfile = async () => {
      const userData = await getMyProfile();
      setUser(userData);
    };
    fetchProfile();
  }, []);

  return (
    <div className="container mx-auto max-w-7xl md:px-8 lg:px-24">
      <Helmet title="Profil - KOUDPOUSS" />
      <Header />
      <section className="bg-white px-6 pb-20 pt-6">
        {/* Menus */}
        <SubHeader currentPage={1} />
        {/* Profil */}
        <div className="border border-gray-200 bg-white py-10 shadow-md">
          <h1 className="mb-6 text-center text-2xl text-primary">Profil</h1>
          <figure className="mb-5 flex items-end border-b border-gray-300 px-10 pb-5">
            <span
              className="mr-3 h-24 w-24 rounded-full border-4 border-slate-200 bg-cover bg-no-repeat"
              style={{
                backgroundImage: `url(${user.profile})`,
              }}
            />
            <figcaption className="flex items-end pb-3">
              {user.verified && <IconCheckCircle color="#058300" />}
              <p className="ml-3">
                <strong className="block text-black">{user.name}</strong>
                <span className="text-sm">
                  {user.verified && 'Profil vérifié'}
                </span>
              </p>
            </figcaption>
          </figure>
          <div className="flex flex-wrap justify-between gap-y-4 px-10">
            {user.fields?.map((field) => (
              <div
                key={`private_${field.title}`}
                className={`mt-3 flex flex-col ${
                  field.semiWidth ? 'sm:w-1/2' : 'w-full '
                }`}
              >
                <div className="flex flex-col items-start justify-start space-y-1">
                  <h3 className="mb-3 text-xl leading-normal text-gray-300">
                    {field.title}
                  </h3>
                </div>
                <div className="w-full rounded border border-gray-300 bg-white p-3.5">
                  {field.value}
                </div>
              </div>
            ))}
          </div>
          <div className="px-10">
            <Player
              playsInline
              // height={500}
              poster="/assets/events.jpeg"
              src="/assets/koudpouss.mp4"
              className="mx-auto mt-10"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Profile;
